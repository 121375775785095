import React, { useCallback,useEffect,useState,useRef,useContext } from 'react'
import { useNavigate} from 'react-router-dom';
import './App.css';
import "./css/HomePage4.css";
import "./css/Button.css";
import concordiumNamingService from "./img/img/concordium-naming-service@2x.png";
import shopcartOutline from "./img/img/shopcart-outline@2x.png";
import domainOutline from "./img/img/domain-outline@2x.png";
import SearchInput from "./SearchInput";
import {UserContext,jtoast} from "./context/context";
import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';
let _WALLET_INFO:any={
	provider:null,
	account:'',
	genesisHash:''
	
};
const JApp=()=>{
	const navigate=useNavigate();
	const {walletInfo,updateWalletInfo} = useContext(UserContext);
	return (
	<>
	<div className='container mt-4'>
		<div className="d-flex justify-content-center">
			<div className='text-center'>
				<img className="domain-outline" src={domainOutline} alt="domain-outline" />
				<div className="gilroy-semi-bold-white-72px">
				  <div><span className="gilroy-bold-white-72px">Mint, Buy, and Sell</span>
				  <span className="gilroy-bold-ronchi-72px">.CCD</span></div>
				  <div className="gilroy-bold-white-72px py-4">Domains</div>
				</div>
				<p className="satoshi-bold-iron-28px pb-2" style={{lineHeight:'30px'}}>Secure your .ccd domain for your journey through <br/>the Concordium ecosystem.</p>
				
			</div>	
				
			
		</div>
		{/*<div className="d-flex justify-content-center">
			<SearchInput/>
		</div>*/}	
		<div className="col-md-8 mx-auto ">
			<SearchInput/>
		</div>
    </div>
	</>
  );
}

export default JApp;
