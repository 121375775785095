import React, { useCallback,useEffect,useState,useRef,useContext,useMemo } from 'react'
import { Link ,useNavigate,useLocation} from 'react-router-dom';
import './App.css';
import "./css/HomePage4.css";
import "./css/Button.css";
import loadingGif from './icons/loading.gif';
import concordiumNamingService from "./img/img/concordium-naming-service@2x.png";
import shopcartOutline from "./img/img/shopcart-outline@2x.png";
import domainOutline from "./img/img/domain-outline@2x.png"; 
import walletIcon from "./img/img/wallet.png";
import ccdIcon from "./img/img/ccd.jpg";
import cryptoX from "./img/img/cryptoX.png";
import axios from 'axios';

import {UserContext,jtoast} from "./context/context";
import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';
import {
    AccountTransactionType,
    CcdAmount,
    ModuleReference,
	serializeUpdateContractParameters,
    toBuffer,
	TransactionStatusEnum ,
	UpdateContractPayload,
	serializeTypeValue,
	getContractName,
	JsonRpcClient,
	
	InstanceInfo
} from '@concordium/web-sdk';
import { CCD_NETWORK,URLPREFIX,RAW_SCHEMA,RAW_SCHEMA_JSON,CCD_INFO } from './constant';
import {
    BrowserWalletConnector,
    WalletConnectConnector,
    WalletConnection,
    WalletConnectionDelegate,
	TESTNET,
	MAINNET,
	CONCORDIUM_WALLET_CONNECT_PROJECT_ID,
	WalletConnectConnection,
	typeSchemaFromBase64,
	moduleSchemaFromBase64,
	
	
} from '@concordium/wallet-connectors';
import {  ok } from 'neverthrow';
let _WALLET_INFO:any={
	provider:null,
	account:'',
	genesisHash:'',
	walletName:''
	
};
class MyDelegate implements WalletConnectionDelegate {
    accounts = new Map<WalletConnection, string | undefined>();
    chains = new Map<WalletConnection, string | undefined>();

    onAccountChanged = (connection: WalletConnection, address: string | undefined) => {
        this.accounts.set(connection, address);
    };

    onChainChanged = (connection: WalletConnection, genesisHash: string) => {
		console.log("*************Chaine:"+genesisHash)
        this.chains.set(connection, genesisHash);
    };

    onConnected = (connection: WalletConnection, address: string | undefined) => {
		console.log("*************Conbneted Account:"+address)
        this.onAccountChanged(connection, address);
    };

    onDisconnected = (connection: WalletConnection) => {
        this.accounts.delete(connection);
        this.chains.delete(connection);
    };
}
const JMenuBar=()=>{
	const {walletInfo,updateWalletInfo} = useContext(UserContext);
	const navigate=useNavigate();
	const [currentAccount,setCurrentAccount]=useState<any>("");
	const [reqWait,setReqWait]=useState<boolean>(false);
	const [showWalletsModal,setShowWalletsModal]=useState<boolean>(false);
	useEffect(()=>{
		console.log("Child:// Context updated");
		_WALLET_INFO.provider=walletInfo.provider;
		_WALLET_INFO.account=walletInfo.account;
		_WALLET_INFO.genesisHash=walletInfo.genesisHash;
		setCurrentAccount(walletInfo.account);
		if(walletInfo.provider===null){
			//navigate(URLPREFIX+"/")
		}
	},[walletInfo]);
	
	const connectWalletConcordiumWallet=async(e:any)=>{
		try{

			//if(_WALLET_INFO.provider!=null) _WALLET_INFO.provider
			_WALLET_INFO.provider=null;
			_WALLET_INFO.account='';
			_WALLET_INFO.genesisHash='';
			let tmpprovider:any= await detectConcordiumProvider();
			//console.log(tmpprovider);
			//tmpprovider.conn
			//return;
			console.log("Selected Wallet");
			let tmpgenesisHash=await tmpprovider.getSelectedChain();
			let tmpaccount=await tmpprovider.connect();
				tmpaccount=await tmpprovider.getMostRecentlySelectedAccount();
			if (tmpaccount!='') {
				if(CCD_INFO.GENESIS_BLOCK_HASH===tmpgenesisHash){
					_WALLET_INFO.provider=tmpprovider;
					_WALLET_INFO.genesisHash=tmpgenesisHash; 
					_WALLET_INFO.account= tmpaccount;
					setCurrentAccount(_WALLET_INFO.account);
					updateWalletInfo(_WALLET_INFO);
					jtoast.success("connected to [Mainnet]");
					setShowWalletsModal(false);
					_WALLET_INFO.provider.on('accountChanged', async(_account:string)=>{
						console.log("Account Changed:"+_account);
						_WALLET_INFO.genesisHash=await tmpprovider.getSelectedChain(); 
						if(CCD_INFO.GENESIS_BLOCK_HASH===_WALLET_INFO.genesisHash){
							_WALLET_INFO.account=_account;
							setCurrentAccount(_account);
							updateWalletInfo(_WALLET_INFO);
							
						}
						else {
							_WALLET_INFO.provider=null;
							_WALLET_INFO.account='';
							_WALLET_INFO.genesisHash='';
							setCurrentAccount('');
							updateWalletInfo(_WALLET_INFO);	
							document.location=URLPREFIX+"/";
						}
					});
					_WALLET_INFO.provider.on('accountDisconnected', () =>{
						console.log("Diconnected");
						_WALLET_INFO.provider=null;
						_WALLET_INFO.account='';
						_WALLET_INFO.genesisHash='';
						setCurrentAccount('');	
						updateWalletInfo(_WALLET_INFO);  
						document.location=URLPREFIX+"/";
					}
					);
					_WALLET_INFO.provider.on('chainChanged', async(_chain:string) =>{
						if(CCD_INFO.GENESIS_BLOCK_HASH===_chain){
							_WALLET_INFO.account=await _WALLET_INFO.provider.getMostRecentlySelectedAccount();
							_WALLET_INFO.genesisHash=_chain;
							setCurrentAccount(_WALLET_INFO.account);
							updateWalletInfo(_WALLET_INFO);
							
							}
						else {
							_WALLET_INFO.provider=null;
							_WALLET_INFO.account='';
							_WALLET_INFO.genesisHash='';
							setCurrentAccount('');
							updateWalletInfo(_WALLET_INFO);	
							document.location=URLPREFIX+"/";
						}	
					});
				}
				else jtoast.error("Kindly select mainnet account in browser wallet");
			} else {
				jtoast.error("Please connect wallet");
			}
			
		}catch(e:any){
			jtoast.error("Concordium wallet not found");
		}
	}
	
	const connectWalletCryptoXWallet=async(e:any)=>{
		
		const delegate =  new MyDelegate();
		const walletConnectConnector:WalletConnectConnector = await WalletConnectConnector.create(
					{
						projectId:CONCORDIUM_WALLET_CONNECT_PROJECT_ID
					}, delegate, MAINNET);
		const walletConnectConnection:any= await walletConnectConnector.connect();
		
		const accountSelected:any=delegate.accounts.get(walletConnectConnection);
		const genesisHash:any=CCD_INFO.GENESIS_BLOCK_HASH;//delegate.chains.get(walletConnectConnection);
		console.log("walletConnectConnection");
		console.log(walletConnectConnection);
		return;
		let jsonRpcClient:any=walletConnectConnection.getGrpcClient()
		try{
			const info:any = await jsonRpcClient.getInstanceInfo({ index: BigInt(CCD_INFO.CONTRACT_INDEX), subindex: BigInt(0) });
			console.log("info");
			console.log(info);
			const contractName = getContractName(info);
			const receiveName = `${contractName}.mint`;
			console.log("RECEIVE ANME::"+receiveName);
		}catch(e){console.log("ERRR:"+e)}
		console.log("genesisHash::"+genesisHash);
		if(CCD_INFO.GENESIS_BLOCK_HASH===genesisHash){
			_WALLET_INFO.walletName='XCryptoWallet';
			_WALLET_INFO.provider=walletConnectConnection;
			_WALLET_INFO.account=''+accountSelected;
			_WALLET_INFO.genesisHash=''+genesisHash;
			setCurrentAccount(_WALLET_INFO.account);
			updateWalletInfo(_WALLET_INFO);
			setShowWalletsModal(false);
		}
		
	}
	return (
	<>	
	<div className='container mt-4'>
	<div className="d-flex ">
		<div className='flex-grow-1 align-self-center'>
			<Link to={URLPREFIX+"/"}>
			<img className="concordium-naming-service" src={concordiumNamingService} alt="Concordium Naming Service" />
			</Link>
		</div>
		<div className=''>
				
				<div className="frame-30-1">
					{<div className="frame-28 pointer">
					  <div className="docs satoshi-bold-white-18px">
					  <Link to={URLPREFIX+"/"} style={{textDecoration:'none',color:'#fff'}}>Home</Link>
					  </div>
					</div>}
					<div className="frame-28 pointer">
					  <div className="docs satoshi-bold-white-18px">
					  <Link to={URLPREFIX+"/"} style={{textDecoration:'none',color:'#fff'}}>Marketplace <span className='f75rem'>(Coming soon)</span></Link>
					  </div>
					</div>
					<div className="button-1 pointer overflow-hidden" >
					  <div className="bg-1"></div>
					  <div className="copy-al-1">
						<div className="connect-wallet satoshi-bold-white-18px position-relative overflow-hidden ellipsis"
						style={{maxWidth:'200px'}}
						onClick={e=>setShowWalletsModal(true)}
						>{walletInfo.account===''?"Connect Wallet":currentAccount}</div>
						
					  </div>
						
					</div>
					{walletInfo.account!==''?
					<div className="frame-28 pointer">
					  <div className="docs satoshi-bold-white-18px"
					  role='btn'
					  id="dropdownConnect" data-bs-toggle="dropdown" aria-expanded="false"
					  ><i className="bi bi-three-dots-vertical"></i></div>
					  <ul className="dropdown-menu position-absolute dropdown-menu-end" aria-labelledby="dropdownConnect" style={{zIndex:999999}}>
							<li><div className='card mb-4'>
								<div className='card-body'>
									<div className='text-black f85rem mb-2 d-block'>Account address</div>
									<a className='ja' target="_blank" href={("https://ccdscan.io/accounts?dcount=1&dentity=account&daddress="+_WALLET_INFO.account)}>
										<div className='text-black ellipsis f1rem'>{_WALLET_INFO.account} <i className="bi bi-box-arrow-up-right"></i></div>
									</a>
								</div>	
								</div>
							</li>
							<li><Link className="dropdown-item" to={URLPREFIX+"/book-domain-list"}>My Domains</Link></li>
							<li><Link className="dropdown-item" to={URLPREFIX+"/profile"}>Profile</Link></li>
							<li><button className="dropdown-item" role='button'
								onClick={async(e)=>{
								_WALLET_INFO.provider=null;
								_WALLET_INFO.account='';
								_WALLET_INFO.genesisHash='';	
								 await updateWalletInfo(_WALLET_INFO);  
								 document.location=URLPREFIX+"/";
								}}
								>Logout</button></li>
					  </ul>
					</div>
					:
					<></>
					}
					
				</div>
		</div>
	</div>
	</div>
	{showWalletsModal===true?

	<div className="position-fixed  vh-100 vw-100 bg-black-65 start-0 top-0" style={{zIndex:9999999,paddingTop:'6rem'}}>
			<div className='col-md-4 mx-auto px-4 pb-4 shadow-lg border border-2 rounded-4 bg-black-metal'>
				<div className="d-flex align-items-center">
					<div className="flex-grow-1 fs-4 mt-2 mb-4 text-white ">
						&nbsp;
					</div>
					<div className="flex-shrink-0 fs-6 mt-4 mb-4 text-white pointer"
					onClick={(e:any)=>{
							setShowWalletsModal(false);
					}}
					>
						Close
					</div>
				</div>
				<div className="d-flex align-items-center">
					<div className="flex-grow-1 fs-4 mt-2 mb-4 text-white text-center ">
						<img src={walletIcon} style={{height:'96px'}}/>
					</div>
				</div>
				<div className="d-flex align-items-center">
					<div className="flex-grow-1 fs-4 mt-2 mb-4 text-white text-center ">
						Connect Wallet to continue<br/>
						<span className='f85rem text-muted'>Concordium based Wallets</span>
					</div>
				</div>	
				<div className="d-flex border border-1 border-warning px-2 py-2 bg-light rounded mt-4 pointer"
				onClick={(e:any)=>{
					connectWalletConcordiumWallet(e);
				}}
				>
					<div className="d-flex align-items-center">
						<div className="flex-shrink-0">
							<img src={ccdIcon} className="img-sm" />
						</div>
						<div className="flex-grow-1 ms-3">
							<span className='fs-5 fw-bold'>Concordium Wallet</span>
						</div>
					</div>
				</div>
				{/*
				<div className="d-flex border border-1 border-warning px-2 py-2 bg-light rounded mt-2 pointer"
				onClick={(e:any)=>{
					connectWalletCryptoXWallet(e);
				}}
				>
					<div className="d-flex align-items-center">
						<div className="flex-shrink-0">
							<img src={cryptoX} className="img-sm" />
						</div>
						<div className="flex-grow-1 ms-3">
							<span className='fs-5 fw-bold'>CryptoX Concordium Wallet</span>
						</div>
					</div>
				</div>
				*/}
				<div className='mt-4 mb-4'>

				</div>
			</div>
	</div>
	:null}
	</>
  );
}

export default JMenuBar;
