import React, { useCallback,useEffect, useState, useRef } from 'react'
import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';
import {
	createConcordiumClient,
    AccountTransactionType,
    CcdAmount,
    ModuleReference,
	deserializeReceiveReturnValue,
    serializeUpdateContractParameters,
    toBuffer,
	TransactionStatusEnum ,
	UpdateContractPayload,
	SimpleTransferWithMemoPayload,
	AccountAddress,
	DataBlob,
	SchemaVersion,
	serializeTypeValue,
	deserializeTypeValue
} from '@concordium/web-sdk';
import { Buffer } from 'buffer/index.js';
import { RAW_SCHEMA,RAW_SCHEMA_JSON,CCD_INFO } from './constant';
import * as borsh from 'borsh';
import { Stats } from 'fs';
//var Buffer = require('buffer/').Buffer;
const CCD_DECIMAL=1000000;
const MAINNET_GENESIS_BLOCK_HASH = '9dd9ca4d19e9393877d2c44b70f89acbfc0883c2243e5eeaecc0d1cd0503f478';
const TESTNET_GENESIS_BLOCK_HASH = '4221332d34e1694168c2a0c0b3fd0f273809612cb13d000d5c2e00e85f50f796';
const contractName="ccd";
const contractIndex:any=CCD_INFO.CONTRACT_INDEX;
//https://concordium-explorer.nl/
//https://ccdscan.io/
//https://sctools.mainnet.concordium.software/
// https://socket.dev/npm/package/@concordium/browser-wallet-api-helpers
//https://github.com/Concordium/concordium-browser-wallet/blob/main/examples/voting/src/Wallet.js
//const deadlineMinutes = Number.parseInt(deadlineMinutesInput, 10);
//https://github.com/Concordium/concordium-node-sdk-js/blob/main/pages/transactions.md#create-a-simple-transfer-with-or-without-memo
//https://github.com/Concordium/concordium-node-sdk-js/blob/main/pages/transactions.md#create-a-register-data-transaction

const AppNFT=()=>{
  const [provider,setProvider]=useState<any>(null);
  const [currentAccount,setCurrentAccount]=useState<any>(null);
  const [genesisHash,setGenesisHash]=useState<any>(null);
  
  const connectWallet=async()=>{
	const _provider = (await detectConcordiumProvider());
	const _genesisHash = await _provider.getSelectedChain();
	let _account = await _provider.connect();  
	_account = await _provider.getMostRecentlySelectedAccount();
	if (_account) {
		setCurrentAccount(_account);
		setProvider(_provider);
		setGenesisHash(_genesisHash);
		
	} else {
		console.log("Not Connected To Wallet");
	}
	 
  }
  const getBalanceOf=async(_account:any,_tokenId:any)=>{
	try{
		let param=[
					{
						address: {
							Account: [_account],
						},
						token_id: _tokenId,
					},
				];
		const paramx:any = serializeUpdateContractParameters('ccd','balanceOf',param,toBuffer(RAW_SCHEMA, 'base64'));
		
		let returnValue:any=await provider.getGrpcClient().invokeContract({
		contract: { index: BigInt(CCD_INFO.CONTRACT_INDEX), subindex: BigInt(0) },
		method: 'ccd.balanceOf',
		parameter:paramx
	});
				if (returnValue && returnValue.tag === 'success' && returnValue.returnValue) {
						const result=returnValue.returnValue;
						console.log(result);
						const response=deserializeReceiveReturnValue(
							toBuffer(result, 'hex'),
							toBuffer(RAW_SCHEMA, 'base64'),
							'ccd',
							'balanceOf',
							2
						);
						if(response && response.length===1) return(parseInt(response[0]));
						return(0);
				} else {
					return(-1);
				}
	}catch(e:any){
		return(-1);
	}	
   }	
  const cscontract=async()=>{
	//initCOntract

	let moduleRef:any=CCD_INFO.CONTRACT_MODULE_REF;
	const parameter = {
            should_add:false,
			value:10
        };
		
	const txHash = await provider.sendTransaction(
            currentAccount,
            AccountTransactionType.InitContract,
            {
                amount: new CcdAmount(BigInt(0)),
                moduleRef: new ModuleReference(moduleRef),
                initName: contractName,
                maxContractExecutionEnergy: BigInt(30000),
            },
            parameter
			//,RAW_SCHEMA_BASE64
        );
        console.log({ txHash });
	
	}
  const getDomain=async()=>{
	 //04149040 00b488f7cf90010000
	//let isCOwner=await isContractOwner(currentAccount);  "FAABAAAABgAAAGRvbWFpbhYC"
	let domain={ domain:"app.ccd"};
	//const param:any = serializeUpdateContractParameters('ccd','getDomain',domain,toBuffer(RAW_SCHEMA,"base64"),SchemaVersion.V1);
	const param:any = serializeTypeValue(domain,toBuffer("FAABAAAABgAAAGRvbWFpbhYC","base64"));
	let returnValue:any=await provider.getGrpcClient().invokeContract({
        contract: { index: BigInt(contractIndex), subindex: BigInt(0) },
        method: 'ccd.getDomain',
		parameter:param
    });
	
	if (returnValue && returnValue.tag === 'success' && returnValue.returnValue) {
						const result=returnValue.returnValue;
						//const buffer = toBuffer(result, 'hex');
						
						const response:any=deserializeTypeValue(
							toBuffer(result, 'hex'),
							toBuffer("FAADAAAACAAAAHRva2VuX2lkHQAFAAAAb3duZXIVAgAAAAcAAABBY2NvdW50AQEAAAALCAAAAENvbnRyYWN0AQEAAAAMCQAAAGV4cGlyZV9hdAU", 'base64'),
						);
						console.log("response Deserialized");
						console.log(response);
						console.log("Expire At:"+new Date(response.expire_at));
						
                    } else {
						console.log("Fail");
                        
                    }
  }
  const getMetadata=async()=>{
	
	const param:any = serializeUpdateContractParameters('ccd','tokenMetadata',["26720000"],toBuffer(RAW_SCHEMA, 'base64'));
	
	provider.getGrpcClient().invokeContract({
        contract: { index: BigInt(contractIndex), subindex: BigInt(0) },
        method: 'ccd.tokenMetadata', parameter:param
		}).then((returnValue:any) => {
                    console.log('Resturen Value');
					console.log(returnValue);
                    if (returnValue && returnValue.tag === 'success' && returnValue.returnValue) {
                        const bufferStream = toBuffer(returnValue.returnValue, 'hex');
                        const length = bufferStream.readUInt16LE(2);
                        const url = bufferStream.slice(4, 4 + length).toString('utf8');
                        console.log("URL:"+url);
                        console.log("Length:"+length);
                        console.log(bufferStream);
                    } else {
						console.log("Fail");
                        
                    }
		});
	
	
  }
    //cargo concordium build  --schema-json-out "./"
	const MINT_HOST = 'http://localhost:8899';
	
  const mintNFT=async()=>{
	let domainName="abcdefgh.ccd";
	let account: any=currentAccount;
	const id:string = Math.round(Math.random() * 100000).toString().padEnd(8, '0');
	let url: string="https://ipfs.io/ipfs/Qmai2z4Dn9AHJ3goJZ7q5ox3mE6vdyJpqzcwoQgFLeL46o";
	let parma0:any={
                owner: {
                    Account: [account],
                },
				tokens: [id],
				domain:domainName,
				days:365,
				metadata_url: url
            }			
	const param:any = serializeUpdateContractParameters('ccd','mint',parma0,toBuffer(RAW_SCHEMA, 'base64'));
	console.log("Schemea MINT NFT PARAM");
	console.log(param);
	
	
	provider.sendTransaction(
                        currentAccount,
                        AccountTransactionType.Update,
                        {
                            amount: new CcdAmount(0n),
                            address: { index: BigInt(contractIndex), subindex: BigInt(0) },
                            receiveName: 'ccd.mint',
                            maxContractExecutionEnergy: 30000n,
                        } as UpdateContractPayload ,
                        parma0
						,RAW_SCHEMA
                    )
                    .then((txHash:any) =>{
						console.log("Response");
						console.log(txHash);
                        /*setTimeout(function listen() {
                            provider
                                .getGrpcClient()
                                .getTransactionStatus(txHash)
                                .then((status) => {
                                    if (
                                        status &&
                                        status.status === TransactionStatusEnum.Finalized &&
                                        status.outcomes
                                    ) {
                                        const outcome = Object.values(status.outcomes)[0];
                                        if (outcome.result.outcome === 'success') {
                                            resolve(txHash);
                                        } else {
                                            reject(new Error('failed'));
                                        }
                                        // return Index
                                    } else {
                                        setTimeout(listen, 3000);
                                    }
                                });
                        }, 3000)*/
					}
                    );
	
	
	
	
  }// mintNFT
  // isContractOwner
  const isContractOwner = async(account: any) => {
			let info:any=await provider.getGrpcClient().getInstanceInfo({ index: BigInt(contractIndex), subindex: BigInt(0) });
			if(info && info.owner.address===account) return(true);
			else return(false);					
  };
	
  const upMetadata=()=>{
	let pinataApiKey="239036809c7f9bc6e8d5";
	let pinataSecretApiKey="6aa898271297185f57f4439cfda1ec44ee53b36f3a62dd99c37a08701bd9225e";
	let loadedImage="https://ipfs.io/ipfs/Qmd2mUxo5FcebTcWTpiVaV9AU7tPP1Fq4FkroQkJPCKAyR";
	const jss = {
        name:"CCN DOMAIN",
        description: "CCN DOMAIN",
        display: {
            url: loadedImage,
        },
        thumbnail: {
            url: loadedImage,
        },
        unique: true,
		attributes: [{
			"type": "date",
			"name": "Birthday",
			"value": "1629792199610"
		}]
    };
	const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
			return axios
				.post(url, jss, {
					headers: {
						pinata_api_key: pinataApiKey,
						pinata_secret_api_key: pinataSecretApiKey,
						token:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI2OTIzZDE2NS1jZmUxLTQ5ZGUtODFhYi1jYWUxZWZkOGExMjIiLCJlbWFpbCI6ImluZm9Ad2ViMjMuaW8iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGluX3BvbGljeSI6eyJyZWdpb25zIjpbeyJpZCI6IkZSQTEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX0seyJpZCI6Ik5ZQzEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX1dLCJ2ZXJzaW9uIjoxfSwibWZhX2VuYWJsZWQiOmZhbHNlLCJzdGF0dXMiOiJBQ1RJVkUifSwiYXV0aGVudGljYXRpb25UeXBlIjoic2NvcGVkS2V5Iiwic2NvcGVkS2V5S2V5IjoiMTM5N2Y0N2RmMTViZmY0YzViMGMiLCJzY29wZWRLZXlTZWNyZXQiOiJlMGJmZjBlYjhhMmNkZjc1NTZjMWMwMzI0ZjMwM2VmYWZmODY0MTg1NzFlMDAxODFkNzkwNGVkYmZjZGM1MmQ1IiwiaWF0IjoxNjYwMzg3MTkzfQ.GTDBYJjdvijK_4k4PpMmadLKg1B0I_sdxndN0NuwRp0'
					}
				})
				.then(function (response) {
					console.log(response.data);
					return response.data;
				})
				.catch(function (error) {
					console.log(error);
					return(null);
				});
  }	  

  const bid=async()=>{
	
	let parma0:any={
                domain:"abcdefgh.ccd",
				amount:15
            }			
	const param:any = serializeUpdateContractParameters('ccd','bid',parma0,toBuffer(RAW_SCHEMA, 'base64'));
	provider.sendTransaction(
                        currentAccount,
                        AccountTransactionType.Update,
                        {
                            amount: new CcdAmount(0n),
                            address: { index: BigInt(contractIndex), subindex: BigInt(0) },
                            receiveName: 'ccd.bid',
                            maxContractExecutionEnergy: 30000n,
                        } as UpdateContractPayload ,
                        parma0
						,RAW_SCHEMA
                    )
                    .then((txHash:any) =>{
						console.log("Response");
						console.log(txHash);
                        
					}
                    );
	
	
	
	
  }// BId

  // bid clise
  const bidClose=async()=>{
	
	let parma0:any={
                domain:"testrest.ccd"
            }			
	const param:any = serializeUpdateContractParameters('ccd','bidClose',parma0,toBuffer(RAW_SCHEMA, 'base64'));
	provider.sendTransaction(
                        currentAccount,
                        AccountTransactionType.Update,
                        {
                            amount: new CcdAmount(0n),
                            address: { index: BigInt(contractIndex), subindex: BigInt(0) },
                            receiveName: 'ccd.bidClose',
                            maxContractExecutionEnergy: 30000n,
                        } as UpdateContractPayload ,
                        parma0
						,RAW_SCHEMA
                    )
                    .then((txHash:any) =>{
						console.log("Response");
						console.log(txHash);
                        
					}
                    );
	
	
	
	
  }// BId lcose
  const auction=async()=>{
	
	let parma0:any={
		domain: "abcdefgh.ccd",
		atype:1,
		price:10,
		start_at:1691653275941,
		end_at:1691689275941
            }			
	const param:any = serializeUpdateContractParameters('ccd','prepareAuction',parma0,toBuffer(RAW_SCHEMA, 'base64'));
	provider.sendTransaction(
                        currentAccount,
                        AccountTransactionType.Update,
                        {
                            amount: new CcdAmount(0n),
                            address: { index: BigInt(contractIndex), subindex: BigInt(0) },
                            receiveName: 'ccd.prepareAuction',
                            maxContractExecutionEnergy: 30000n,
                        } as UpdateContractPayload ,
                        parma0
						,RAW_SCHEMA
                    )
                    .then((txHash:any) =>{
						console.log("Response");
						console.log(txHash);
                        
					}
                    );
	
	
	
	
  }// Auction
  function decodeString(buffer:any, offset:any) {
    const length = buffer.readUInt32LE(offset);
    offset += 4;
    return [buffer.slice(offset, offset + length).toString('utf8'), offset + length];
	}
  const getAuction=async()=>{
   let domain={ domain:"testrest.ccd"};
   const param:any = serializeUpdateContractParameters('ccd','getAuction',domain,toBuffer(RAW_SCHEMA, 'base64'));
   
   
   let returnValue:any=await provider.getGrpcClient().invokeContract({
	   contract: { index: BigInt(contractIndex), subindex: BigInt(0) },
	   method: 'ccd.getAuction',
	   parameter:param
   });
   
   if (returnValue && returnValue.tag === 'success' && returnValue.returnValue) {
					   const result=returnValue.returnValue;
					  /* console.log(result);
					   let buffer=toBuffer(result, 'hex');
					   let offset:number=0;
					   const status = buffer.readUInt8(0); offset=1;
					   const [domain, offset_] = decodeString(buffer, offset);offset=offset_;
					   const atype = buffer.readUInt8(offset);offset=offset+1;
					   const price = buffer.readUInt32LE(offset);offset=offset+4;
					   const ownerLen =buffer.readUInt8(offset); offset=offset+1;
					   const owner = AccountAddress.fromBytes(buffer.slice(offset,offset+32)); offset=offset+32;
					   const startAt = buffer.readBigUInt64LE(offset);offset=offset+8;
					   const endAt = buffer.readBigUInt64LE(offset);offset=offset+8;
					   const bidderLen =buffer.readUInt8(offset); offset=offset+1;
					   const bidder = AccountAddress.fromBytes(buffer.slice(offset,offset+32)); offset=offset+32;
					   const bidAmount = buffer.readUInt32LE(offset);offset=offset+4;
					   const bidAt = buffer.readBigUInt64LE(offset);offset=offset+8;
					   console.log("Offset="+(offset)+"::"+buffer.length);
					   console.log("status="+status);
					   console.log("domain="+domain);
					   console.log("atype="+atype);
					   console.log("prcie="+price);
					   console.log("OwnerLen="+ownerLen);
					   console.log("Owner="+owner?.address);
					   console.log("start="+startAt);
					   console.log("end="+endAt);
					   console.log("Bidders="+bidderLen);
					   console.log("Bidder="+bidder?.address);
					   console.log("Bid Amount="+bidAmount);
					   console.log("Bid At="+bidAt);
					   const tokenId = buffer.readBigUInt64LE(offset);offset=offset+8;
					   console.log("tokenId="+tokenId);
					   //console.log("owner="+owner);*/
					    const response=deserializeReceiveReturnValue(
						   toBuffer(result, 'hex'),
						   toBuffer(RAW_SCHEMA, 'base64'),
						   'ccd',
						   'getAuction',
						   2
					   );
					   console.log("response Deserialized");
					   console.log(response);
					} else {
					   console.log("Fail");
		}
 }
 const transferCCD=async()=>{
	
	let ccdAmount=1;
	const toAddress = new AccountAddress("4tEZ552J7gr11FDrvyDMq1W4pzaHqCmdRzD3gXcc6jyoatuZCo");		
	let simpleTransfer = null;
	let memo:string='["rahul.ccd",6684,"67889945",new Date().getTime()]';
	if (memo.length>=3) {
		simpleTransfer = {
			amount: new CcdAmount(BigInt(Number(ccdAmount)*CCD_INFO.CCD_DECIMAL)),
			toAddress:toAddress,
			memo:new DataBlob(Buffer.from("hello", 'hex')),
		} as SimpleTransferWithMemoPayload;
		//memo:new DataBlob(Buffer.from(memo, 'hex')),
	} else {
		/*simpleTransfer = {
			amount: new CcdAmount(BigInt(Number(ccdAmount)*CCD_INFO.CCD_DECIMAL)),
			toAddress,
		};*/
	}
	
	provider.sendTransaction(
		currentAccount,
		AccountTransactionType.TransferWithMemo,
		{
			
			amount: new CcdAmount(BigInt(Number(ccdAmount)*CCD_INFO.CCD_DECIMAL)),
			toAddress:toAddress,
			memo:new DataBlob(Buffer.from("hello", 'hex')),
		} as SimpleTransferWithMemoPayload,
		
	)
	.then((txHash:any) =>{
		console.log("Response");
		console.log(txHash);
		setTimeout(function listen() {
			provider
				.getGrpcClient()
				.getTransactionStatus(txHash)
				.then(async(status:any) => {
					if (
						status &&
						status.status === TransactionStatusEnum.Finalized &&
						status.outcomes
					) {
						const outcome:any = Object.values(status.outcomes)[0];
						console.log("Outcome");
						console.log(outcome)
						if (outcome.result.outcome === 'success') {
							console.log("outcome.result.outcome");
							console.log(outcome.result.outcome)
						} else {
							console.log("outcom fail...");
						}
						// return Index
					} else {
						setTimeout(listen, 3000);
					}
				});
		}, 3000)
	}
	);
 }
 const getContractCCDBalance=async()=>{
	const instanceInfo: any = await provider.getGrpcClient().getInstanceInfo(
        { index: BigInt(contractIndex), subindex: BigInt(0) },
    );
	console.log(instanceInfo);
	let ccdBalance=Number(instanceInfo.amount.microCcdAmount / 1000000n);
	console.log("CCD BALANCE in Contarct:"+ccdBalance);
 }
 const withdrawlCCDBalance=async()=>{
	
	let ccd={ ccd:Number(38000)*CCD_INFO.CCD_DECIMAL}; // micro
    //const param:any = serializeUpdateContractParameters('ccd','withdrawal',ccd,toBuffer(RAW_SCHEMA, 'base64'));
	//console.log(param);
	
	provider.sendTransaction(
                        currentAccount,
                        AccountTransactionType.Update,
                        {
                            amount: new CcdAmount(0n),
                            address: { index: BigInt(contractIndex), subindex: BigInt(0) },
                            receiveName: 'ccd.withdrawal',
                            maxContractExecutionEnergy: 30000n,
                        } as UpdateContractPayload ,
                        ccd
						,RAW_SCHEMA
                    )
                    .then((txHash:any) =>{
						console.log("Response");
						console.log(txHash);
                        
					}
    );
 }

 const initAmount=async()=>{
	// amount in ccd
	let initParam={
			  single:Number(9000),
			  double:Number(7200),
			  triple:Number(7200),
			  default:Number(4500)
		};
		/*let initParam={
			single:Number(10),
			double:Number(10),
			triple:Number(10),
			default:Number(10)
	  };*/	
    const param:any = serializeUpdateContractParameters('ccd','initMe',initParam,toBuffer(RAW_SCHEMA, 'base64'));
	console.log(param);
	
	provider.sendTransaction(
                        currentAccount,
                        AccountTransactionType.Update,
                        {
                            amount: new CcdAmount(0n),
                            address: { index: BigInt(contractIndex), subindex: BigInt(0) },
                            receiveName: 'ccd.initMe',
                            maxContractExecutionEnergy: 30000n,
                        } as UpdateContractPayload ,
                        initParam
						,RAW_SCHEMA
                    )
                    .then((txHash:any) =>{
						console.log("Response");
						console.log(txHash);
                        
					}
    );
 }
  return (
    <div className="container">
     <h2>COncordium</h2>
	 <div>Current Account:{currentAccount}</div>
	 <button className='btn btn-primary' onClick={(e)=>{connectWallet();}}>Click Me</button>
	 <button className='btn btn-primary' onClick={(e)=>{cscontract(); }} >Init Smart Contract</button>
	 <button className='btn btn-primary' onClick={(e)=>{initAmount(); }} >Init Minimum Domain Amount</button>
	 <button className='btn btn-primary' onClick={(e)=>{getDomain(); }}>getDomain</button>
	 <button className='btn btn-primary' onClick={(e)=>{mintNFT(); }}>Mint NFT</button>
	 <button className='btn btn-primary' onClick={(e)=>{getMetadata(); }}>Get Metadata</button>
	 <button className='btn btn-primary' onClick={(e)=>{upMetadata(); }}>Upload Metadata to IPFS</button>
	 <button className='btn btn-primary' onClick={async(e)=>{
			let balance=await getBalanceOf(currentAccount,"72397000");
			alert(balance);
			}}>Get Balance</button>
	<button className='btn btn-primary' onClick={async(e)=>{
			let balance=await getContractCCDBalance();
			
			}}>Get Contarct CCD Balance</button>
	<button className='btn btn-primary' onClick={async(e)=>{
			let balance=await withdrawlCCDBalance();
			
			}}>Withdrwal CCD from COntract</button>		
	<button className='btn btn-primary' onClick={async(e)=>{
			let balance=await transferCCD();
			
			}}>Transfer CCD</button>		
	 <div className='mt-4'>
	 	
		<button className='btn btn-primary' onClick={(e)=>{auction(); }}>Prepare Auction</button>
		<button className='btn btn-primary' onClick={(e)=>{getAuction(); }}>get Auction</button>
		<button className='btn btn-primary' onClick={(e)=>{bid(); }}>I am bidding</button>
		<button className='btn btn-primary' onClick={(e)=>{bidClose(); }}>Bid Close</button>

	 </div>
	 <div className='mt-4'>
	 	<h2>Rirect Concordium pipe</h2>
		<button className='btn btn-primary' onClick={async(e)=>{
				const client= createConcordiumClient("http://3.67.121.183",Number("20000"),{ timeout: 60000 });	
				console.log(client);
				const accountAddress = new AccountAddress("3q38WtZRw9TZL4XufxcmBgLMuPzpEvW4Ucp4NiNKUM4iXrFY2J");
                const accountInfo = await client.getAccountInfo(accountAddress);
                console.log(accountInfo);
				
		 }}>Connet Node</button>
		
		
	 </div>
	 <button 
			className='btn btn-primary px-4 py-2'
			onClick={async(e)=>{
				const instanceInfo: any = await provider.getGrpcClient().getInstanceInfo(
					{ index: BigInt(contractIndex), subindex: BigInt(0) },
				);
				console.log(instanceInfo);
				let ccdBalance=Number(instanceInfo.amount.microCcdAmount / 1000000n);
				console.log("CCD BALANCE in Contarct:"+ccdBalance);
				/*const info = grpcClient.getInstanceInfo(contract);
				const contractName = getContractName(info);
				const receiveName = `${contractName}.mint`*/
			}}
			>Get Contract Info</button>
    </div>
  );
}

export default AppNFT;
