import React, { useCallback,useEffect,useState,useRef,useContext } from 'react'
import { useNavigate,useLocation} from 'react-router-dom';
import './App.css';
import "./css/HomePage4.css";
import "./css/Button.css";
import loadingGif from './icons/loading.gif';
import concordiumNamingService from "./img/img/concordium-naming-service@2x.png";
import domainImage from "./images/nft-domain.png";
import shopcartOutline from "./img/img/shopcart-outline@2x.png";
import domainOutline from "./img/img/domain-outline@2x.png";
import axios from 'axios';
import moment from 'moment';

import {UserContext,jtoast} from "./context/context";
import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';
import {
	AccountAddress,
    AccountTransactionType,
    CcdAmount,
    ModuleReference,
    serializeUpdateContractParameters,
	deserializeReceiveReturnValue,
    toBuffer,
	TransactionStatusEnum ,
	UpdateContractPayload,
	
} from '@concordium/web-sdk';
import { URLPREFIX,RAW_SCHEMA,RAW_SCHEMA_JSON,CCD_INFO,decodeString } from './constant';
import JMenuBar from "./JMenuBar";
import JBackBar from "./JBackBar"
let _WALLET_INFO:any={
	provider:null,
	account:'',
	genesisHash:''
	
};
const JAuctionList=()=>{
	const {walletInfo,updateWalletInfo} = useContext(UserContext);
	const navigate=useNavigate();
	const {state}=useLocation();
	
	const [domain,setDomain]=useState<string>("");
	const [currentAccount,setCurrentAccount]=useState<any>("");
	const [reqWait,setReqWait]=useState<boolean>(false);
	const [rows,setRows]=useState<any>([]);
	useEffect(()=>{
		if(walletInfo.account===""){
			navigate(URLPREFIX+"/");
			return;
		}
		_WALLET_INFO.provider=walletInfo.provider;
		_WALLET_INFO.account=walletInfo.account;
		_WALLET_INFO.genesisHash=walletInfo.genesisHash;
		//console.log("OKAY ----");
		setCurrentAccount(walletInfo.account);
		getAuctionDomains(walletInfo.account);
	},[walletInfo]);
	const getAuctionInfo=async(_domain:any)=>{
		try{
			let param={domain:_domain};
			const paramx:any = serializeUpdateContractParameters('ccd','getAuction',param,toBuffer(RAW_SCHEMA, 'base64'));
			let returnValue:any=await _WALLET_INFO.provider.getGrpcClient().invokeContract({
			contract: { index: BigInt(CCD_INFO.CONTRACT_INDEX), subindex: BigInt(0) },
			method: 'ccd.getAuction',
			parameter:paramx
		});
					if (returnValue && returnValue.tag === 'success' && returnValue.returnValue) {
							const result=returnValue.returnValue;
							/*let buffer=toBuffer(result, 'hex');
							let offset:number=0;
							const status = buffer.readUInt8(0); offset=1;
							const [domain, offset_] = decodeString(buffer, offset);offset=offset_;
							const atype = buffer.readUInt8(offset);offset=offset+1;
							const price = buffer.readUInt32LE(offset);offset=offset+4;
							const ownerLen =buffer.readUInt8(offset); offset=offset+1;
							const owner = AccountAddress.fromBytes(buffer.slice(offset,offset+32)); offset=offset+32;
							const startAt = buffer.readBigUInt64LE(offset);offset=offset+8;
							const endAt = buffer.readBigUInt64LE(offset);offset=offset+8;
							const bidderLen =buffer.readUInt8(offset); offset=offset+1;
							const bidder = AccountAddress.fromBytes(buffer.slice(offset,offset+32)); offset=offset+32;
							const biding_amount = buffer.readUInt32LE(offset);offset=offset+4;
							const bid_at = buffer.readBigUInt64LE(offset);offset=offset+8;
							const token_id = decodeString(buffer,offset);
							*/
							const response=deserializeReceiveReturnValue(
								toBuffer(result, 'hex'),
								toBuffer(RAW_SCHEMA, 'base64'),
								'ccd',
								'getAuction',
								2
							);
							console.log("RESPONS--------");
							console.log(response);
							return({
								status:response.status,
								domain:response.domain,
								atype:response.atype,
								price:response.price,
								owner:response.owner,
								startAt:response.start_at,
								endAt:response.end_at,
								bidder:response.bidder,
								bidAmount:response.biding_amount,
								bidAt:response.bid_at,
								tokenId:response.token_id
							});
							
					} else {
						return(null);
					}
		}catch(e:any){
			console.log(e);
			return(null);
		}	
	   }
	   const isTokenOwner=async(_account:any,_tokenId:any)=>{
		try{
			let param=[
						{
							address: {
								Account: [_account],
							},
							token_id: _tokenId,
						},
					];
			const paramx:any = serializeUpdateContractParameters('ccd','balanceOf',param,toBuffer(RAW_SCHEMA, 'base64'));
			let returnValue:any=await _WALLET_INFO.provider.getGrpcClient().invokeContract({
			contract: { index: BigInt(CCD_INFO.CONTRACT_INDEX), subindex: BigInt(0) },
			method: 'ccd.balanceOf',
			parameter:paramx
		});
					if (returnValue && returnValue.tag === 'success' && returnValue.returnValue) {
							const result=returnValue.returnValue;
							//console.log(result);
							const response=deserializeReceiveReturnValue(
								toBuffer(result, 'hex'),
								toBuffer(RAW_SCHEMA, 'base64'),
								'ccd',
								'balanceOf',
								2
							);
							if(response && response.length===1) return(parseInt(response[0]));
							return(0);
					} else {
						return(-1);
					}
		}catch(e:any){
			console.log(e);
			return(-1);
		}	
	   }   
	const getAuctionDomains=async(_owner:any)=>{
		let response=await axios.post(process.env.REACT_APP_SDK_API_SERVER+"/api/marketplace",{owner:_owner});
		console.log(response.data);
		if(response.status===200 && response.data!=undefined){
			let data:any=response.data;
				if(data.status===1){
					//console.log(data.data);
					setRows(data.data);
				}
		}
	}
	const DomainActionPanel=(props:any)=>{
		const [bstatus,setBStatus]=useState<boolean>(true);
		const [auctionInfo,setAuctionInfo]=useState<any>(null);
		useEffect(()=>{
			onLoad("_");
		},[]);
		const onLoad=async(e:any)=>{
			let balance:any=await isTokenOwner(currentAccount,props.token);
			let btn=(window as any).document.getElementById("_"+props.index+"_btn");
			
				let auction=await getAuctionInfo(props.domain);
				//console.log(auction);
				setAuctionInfo(auction);
				let currentTime=new Date().getTime();
				if(auction && auction.status===1 && currentTime>auction.endAt){
					btn.innerHTML="Closed";	
					btn.classList.remove("btn-warning")
					btn.classList.add("btn-danger")	;
					setBStatus(true);	
				}
				else if(auction && auction.status===1){
					btn.innerHTML="Bid";	
					btn.classList.remove("btn-danger")
					btn.classList.add("btn-warning")	;
					setBStatus(false);	
				}
				else{
					btn.innerHTML="Pause";
					btn.classList.remove("btn-warning")
					btn.classList.add("btn-danger")	;
					setBStatus(true);	
				}
			
			
		}
		
		return(
			<div className='d-flex flex-column px-2'>
				
				<div className='d-flex flex-column'>
					<div className='d-flex py-1'><span className='flex-fill text-muted'>Token</span>  <span className='text-dark fw-bold'>{auctionInfo?.tokenId}</span></div>
					<div className='d-flex py-1'><span className='flex-fill text-muted'>Start Bid Price</span>  <span className='text-dark fw-bold'>{auctionInfo?.price} CCD</span></div>
					<div className='d-flex py-1'><span className='flex-fill text-muted'>Current Bid Price</span>  <span className='text-dark fw-bold'>{auctionInfo?.bidAmount} CCD</span></div>
					<div className='d-flex py-1'><span className='flex-fill text-muted'>Expiring In</span>  <span className='text-dark fw-bold'>{moment(auctionInfo?.endAt).format('YYYY-MM-DD')}</span></div>	
				</div>
				<div className='mt-2 text-end'>
				<button className='mb-2 nowrap btn btn-warning rounded-pill px-6 py-2 text-black fw-bold pointer'
						id={'_'+props.index+'_btn'}
						disabled={bstatus}
						onClick={e=>{
							navigate(URLPREFIX+"/bid",{state:{domain:props.domain,token:props.token,royality:props.royality}});
							//iambidding();
						}}
				>Bid</button>
				</div>	
			</div>
		);
	}
  return (
	<>
	<div className='container mt-4'>
	<div className='container py-4 px-4'>
	<JBackBar backTo={URLPREFIX+"/"} />
	<div className='p-4'>
		
		<h2 className='rubik-bold-white-40px'>Marketplace</h2>
		
		<div className='row mt-4 '>
		{rows && rows.map((rec:any,index:Number)=>{
			return(
					<div className='col-md-3 mb-4'>
					<div className='card'>
						<div className='text-center mb-4 py-4' style={{padding:'1px'}}>
							<img src={domainImage} style={{width:'50%'}} />
							<div className='text-center fs-5 text-black' >{rec.domain}</div>
						</div>
						<DomainActionPanel domain={rec.domain} index={index} token={rec.token} royality={rec.royality}/>
						
					{/*<div className='col-md-6'>
						<span className='fs-4 text-white text-shadow px-4 py-1'>{rec.domain}</span>
					</div>
					<div className='col-md-2'>
						<span className='fs-5 text-white text-shadow px-4 py-1 nowrap'>{moment(rec.createdAt).format('YYYY-MM-DD')}</span>
					</div>	
					<div className='col-md-2'>
						<span className='fs-5 text-white text-shadow px-4 py-1 nowrap'>{moment(rec.createdAt).add(rec.life,'d').format('YYYY-MM-DD')}</span>
					</div>
					<div className='col-md-2'>
						<DomainButton domain={rec.domain} index={index} token={rec.token}/>
					</div>*/}
				</div>
				</div>
			);}
		)}	
		</div>		
	</div>
	</div>
	</div>
	</>
  );
}

export default JAuctionList;
