import React, { useCallback,useEffect,useState,useRef,useContext } from 'react'
import { useNavigate,useLocation} from 'react-router-dom';
import './App.css';
import "./css/HomePage4.css";
import "./css/Button.css";
import loadingGif from './icons/loading.gif';
import concordiumNamingService from "./img/img/concordium-naming-service@2x.png";
import shopcartOutline from "./img/img/shopcart-outline@2x.png";
import domainOutline from "./img/img/domain-outline@2x.png";
import axios from 'axios';
import { Result, ResultAsync, err, ok } from 'neverthrow';
import {UserContext,jtoast} from "./context/context";
import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';
import {
    AccountTransactionType,
    CcdAmount,
    ModuleReference,
    serializeUpdateContractParameters,
    toBuffer,
	TransactionStatusEnum ,
	UpdateContractPayload,
	serializeTypeValue,
	AccountTransactionPayload,
	
} from '@concordium/web-sdk';
import {
	WalletConnectConnection,
   typeSchemaFromBase64,
   moduleSchemaFromBase64,
   Schema,
   typeSchema
} from '@concordium/wallet-connectors';
import { URLPREFIX,RAW_SCHEMA,RAW_SCHEMA_JSON,CCD_INFO,currentUSD,IPFS_URL,getTransactionStatus } from './constant';
import JMenuBar from "./JMenuBar";
import JBackBar from './JBackBar';
import { stat } from 'fs';
let _WALLET_INFO:any={
	provider:null,
	account:'',
	genesisHash:'',
	walletName:''
	
};
let priceList:any={
	"a":{
		"1":70,
		"2":120,
		"3":150,
		"5":200
	},
	"ab":{
		"1":60,
		"2":100,
		"3":140,
		"5":170
	},
	"abc":{
		"1":60,
		"2":100,
		"3":140,
		"5":170
	},
	"default":{
		"1":35,
		"2":50,
		"3":75,
		"5":100
	}
};
const JBookDomain=()=>{
	const {walletInfo,updateWalletInfo} = useContext(UserContext);
	const navigate=useNavigate();
	const {state}=useLocation();
	
	const [domain,setDomain]=useState<string>("");
	const [domainExceptTLD,setDomainExceptTLD]=useState<string>("");
	const [availableStatus,setAvailableStatus]=useState<string>("0");
	const [currentAccount,setCurrentAccount]=useState<any>("");
	const [usdRate,setUsdRate]=useState<number>(-1);
	const [ccdRate,setCCDRate]=useState<number>(-1);
	const [priceinDollar,setPriceinDollar]=useState<number>(35);
	const [priceinCCD,setPriceinCCD]=useState<number>(35);
	const [life,setLife]=useState<number>(1);
	const [reqWait,setReqWait]=useState<boolean>(false);
	let token:string="00000000";
	useEffect(()=>{
		_WALLET_INFO.provider=walletInfo.provider;
		_WALLET_INFO.account=walletInfo.account;
		_WALLET_INFO.genesisHash=walletInfo.genesisHash;
		_WALLET_INFO.walletName=walletInfo.walletName;
		setCurrentAccount(walletInfo.account);
	},[walletInfo]);
	useEffect(()=>{
		if(state && state.domain)
			{
				setDomain(state.domain.trim());
				let x=state.domain.split(".");
				let tld=x[x.length-1];
				let domainName=state.domain.trim().substring(0,state.domain.length-tld.length-1);
				setDomainExceptTLD(domainName);
				updatePrice(domainName,"1");
				
			}
		else navigate(URLPREFIX+"/");
	},[state]);
	
	const updatePrice=async(domainName:string,_years:any)=>{
		if(domainName.length===1){setPriceinDollar(priceList.a[_years]);}
		else if(domainName.length===2){setPriceinDollar(priceList.ab[_years]);}
		else if(domainName.length===3){setPriceinDollar(priceList.abc[_years]);}
		else {setPriceinDollar(priceList.default[_years]);}
		let usdRate=await currentUSD();
		setUsdRate(usdRate);
		setCCDRate(parseInt(""+(1/usdRate)));
	}
	const bookDomain=async(domain:string,currentAccount:any,ipfsURL:any)=>{
		try{
			
			let account: any=currentAccount;
			const d=new Date();
			const ss=(1+d.getSeconds()).toString().padEnd(2,'0');
			const id:string = Math.round(Math.random() * 1000000).toString().padEnd(6, '0')+""+ss;
			console.log(id);
			token=id;
			let url: string=""+ipfsURL;
			let parma0:any={
						owner: {
							Account: [account],
						},
						tokens: [id],
						domain:domain,
						days:life*365,
						metadata_url: url
					}			
			const param:any = serializeUpdateContractParameters('ccd','mint',parma0,toBuffer(RAW_SCHEMA, 'base64'));
			let txHash=await _WALLET_INFO.provider.sendTransaction(
								currentAccount,
								AccountTransactionType.Update,
								{
									amount: new CcdAmount(BigInt(priceinDollar*ccdRate*1000000)),
									address: { index: BigInt(CCD_INFO.CONTRACT_INDEX), subindex: BigInt(0) },
									receiveName: 'ccd.mint',
									maxContractExecutionEnergy: 30000n,
								} as UpdateContractPayload ,
								parma0
								,RAW_SCHEMA
							);
			return(txHash);				
			
		}catch(e:any){
			console.log(e);
			return(null);
		}
	}
	const bookDomainXcrypto=async(domain:string,currentAccount:any,ipfsURL:any)=>{
		try{
			
			let account: any=currentAccount;
			const d=new Date();
			const ss=(1+d.getSeconds()).toString().padEnd(2,'0');
			const id:string = Math.round(Math.random() * 1000000).toString().padEnd(6, '0')+""+ss;
			token=id;
			let url: string=""+ipfsURL;
					let parameters:any={
						owner: {
							Account: [account],
						},
						tokens: [id],
						domain:domain,
						days:life*365,
						metadata_url: url
					}	
					console.log(parameters);
					//let schema=typeSchemaFromBase64(RAW_SCHEMA_JSON.entrypoints.mint.parameter);
					let schema=moduleSchemaFromBase64(RAW_SCHEMA);
					console.log("Amount::"+(priceinDollar*ccdRate));	
					let _resultData:any=await ResultAsync.fromPromise(
								(_WALLET_INFO.provider as WalletConnectConnection).signAndSendTransaction(
										account,
										AccountTransactionType.Update,
										{
											amount: new CcdAmount(BigInt(priceinDollar*ccdRate*1000000)),
											address: { index: BigInt(CCD_INFO.CONTRACT_INDEX), subindex: BigInt(0) },
											receiveName: 'ccd.mint',
											maxContractExecutionEnergy: BigInt("30000")
										} as UpdateContractPayload,
										{parameters:parameters,schema:schema}
									),
									(err: any)=>{
										return err;
									}
					).then((_txnData:any)=>{
						
						return(_txnData);
					});
				return(_resultData);		
			
		}catch(e:any){
			console.log(e);
			return(null);
		}
	}
	
	const ubookDomain=async()=>{
		if(usdRate<=0){
			 return;
		}

		setReqWait(true);
		const url = process.env.REACT_APP_SDK_API_SERVER+"/api/uip-fs";
		axios
			.post(url, {domain:domain})
			.then(async function (response) {
				console.log(response.data);
				if(response && response.data.status && response.data.status===1){}
				else {
						jtoast.error(response.data?.error);
						setReqWait(false);	
						return;
				}
				let IPFS:any=response.data?.data;
				// Start Book Domain
					if(IPFS && IPFS.IpfsHash){}
					else {jtoast.error("IPFS is not responding!"); setReqWait(false);return;}
					let txHash:any=null;
					if(_WALLET_INFO.walletName==='XCryptoWallet'){
						txHash=await bookDomainXcrypto(domain,currentAccount,IPFS_URL+IPFS.IpfsHash);
						console.log("TXN Resukt");
						console.log(txHash);
						if(txHash && txHash.error) {
							jtoast.error(txHash.error.message);
							setReqWait(false);	
							return;
						}
					}
					else {
						txHash=await bookDomain(domain,currentAccount,IPFS_URL+IPFS.IpfsHash);
						const blockHash = await _WALLET_INFO.provider.getGrpcClient().waitForTransactionFinalization(txHash);
					}
					
					if(txHash===null){
						jtoast.error("Something went wrong [UBD-500]");
						setReqWait(false);	
					}
					else {
						const hashStatus=await getTransactionStatus(_WALLET_INFO.provider,txHash);
						if(hashStatus.status==="success"){
							let _state:any={
								owner:currentAccount,
								domain:domain,
								contract:CCD_INFO.CONTRACT_INDEX,
								token:token,
								txHash:txHash,
								dollar:priceinDollar,
								ccd:priceinCCD,
								life:life*365,
								ipfs:IPFS_URL+IPFS.IpfsHash
							};
							const _url = process.env.REACT_APP_SDK_API_SERVER+"/api/ccd-mint";	
								try{
									let _response=await axios.post(_url,_state);
									console.log(_response.data);
								}catch(_e:any){
									jtoast.error("Domain has been booked, but something went wrong!");
								}
								setReqWait(false);
								navigate(URLPREFIX+"/book-domain-success",{state:_state});
							return;

						}else if(hashStatus.status==="success"){
							setReqWait(false);
							jtoast.error(""+hashStatus.reason);
						}
						else{
							setReqWait(false);
							jtoast.error("Something went wrong [UBD-400]");
						}
					}
				// ENd Boom DOmain
				
			})
			.catch(function (error) {
				console.log(error);
				setReqWait(false);
				return(null);
			});
		
		
		
		
	}
  return (
	<>
	<div className='container mt-4'>
	<div className='container py-4 px-4'>
	<JBackBar backTo={URLPREFIX+"/"} />
	<div className='p-4'>
		<h2 className='rubik-bold-white-40px'>Booking Domain</h2>
		<div className='d-flex mt-4 p-4'>
			<div className='flex-grow-1'>
				<span className='fs-2 text-white text-shadow px-4 py-1'>{domain}</span>
			</div>
			<div className='d-flex px-6'>
				<span className='fs-2 text-white text-shadow px-4 py-1'></span>
				<div><select className="form-select form-select-lg select-primary"
					  onChange={e=>{setLife(Number(e.target.value));
						updatePrice(domainExceptTLD,e.target.value);
						}}
					>
					<option value="1">1 year</option>
					<option value="2">2 years</option>
					<option value="3">3 years</option>
					<option value="5">5 years</option>
					
					</select>
				</div>	
				<div className="fs-2 text-white text-shadow px-4 py-1 no-wrap" ><span className='f85rem'>Payable amount</span> ${priceinDollar}</div>
			</div>
			<div className=''>
				<button className='btn btn-warning rounded-pill px-6 py-2 text-black  pointer'
				onClick={e=>ubookDomain()}
				disabled={reqWait}
				>
					{reqWait===true?
							<><img src={loadingGif} className='ani-xsm' /></>
						:
							<> Checkout </>
					}
				</button>
			</div>
		</div>	
		{/*<div className='d-flex p-4 justify-content-end'>
			<div className="form-check">
				<div className="form-check form-switch">
					<input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
					<label className="form-check-label text-silver" style={{paddingTop:'4px'}} >
						I accept terms and conditions.
					</label>
				</div>
			</div>
				</div>*/}
	</div>
	</div>
	</div>
	</>
  );
}

export default JBookDomain;

