import React, { useCallback,useEffect,useState,useRef,useContext } from 'react'
import { useNavigate} from 'react-router-dom';
import loadingGif from '../icons/loading.gif';
import SearchIcon from "../SearchIcon";
import "./SearchInput.css";
import "../App.css";
import searchIcon from "../img/img/search-outline@2x.png";
import "./SearchIcon.css";
import {UserContext,jtoast} from "../context/context";
import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';
import {
    AccountTransactionType,
    CcdAmount,
    ModuleReference,
    serializeUpdateContractParameters,
    toBuffer,
	TransactionStatusEnum ,
	UpdateContractPayload,
	
} from '@concordium/web-sdk';
import { URLPREFIX,RAW_SCHEMA,RAW_SCHEMA_JSON,CCD_INFO } from '../constant';

const pre_domain=[];
/*["cns-prelaunch-test-token-1.ccd", 
"cns-prelaunch-test-token-2.ccd", 
"cns-prelaunch-test-token-3.ccd", 
"cns-prelaunch-test-token-4.ccd", 
"cns-prelaunch-test-token-5.ccd", 
"airbnb.ccd", 
"alphabet.ccd", 
"block.ccd", 
"facebook.ccd", 
"flipcart.ccd", 
"tiktok.ccd", 
"walmart.ccd", 
"wayfair.ccd", 
"yandex.ccd", 
"alpha.ccd", 
"black.ccd", 
"bravo.ccd", 
"horde.ccd", 
"white.ccd", 
"agent007.ccd", 
"alliance.ccd", 
"australia.ccd", 
"brazil.ccd", 
"canada.ccd", 
"england.ccd", 
"france.ccd", 
"germany.ccd", 
"india.ccd", 
"indonesia.ccd", 
"israel.ccd", 
"japan.ccd", 
"mexico.ccd", 
"norway.ccd", 
"player1.ccd", 
"player2.ccd", 
"southkorea.ccd", 
"sweden.ccd", 
"switzerland.ccd", 
"1000.ccd", 
"3000.ccd", 
"artdao.ccd", 
"asset.ccd", 
"deepak.ccd", 
"gas.ccd", 
"login.ccd", 
"moon.ccd", 
"mystic.ccd", 
"name.ccd", 
"poker.ccd", 
"rug.ccd", 
"abdul.ccd", 
"ahmed.ccd", 
"ali.ccd", 
"david.ccd", 
"mohamed.ccd", 
"mohammed.ccd", 
"muhammad.ccd", 
"paradigm.ccd", 
"wei.ccd", 
"002.ccd", 
"004.ccd", 
"005.ccd", 
"006.ccd", 
"008.ccd", 
"009.ccd", 
"02.ccd", 
"03.ccd", 
"04.ccd", 
"05.ccd", 
"06.ccd", 
"08.ccd", 
"09.ccd", 
"10.ccd", 
"1111.ccd", 
"2222.ccd", 
"3333.ccd", 
"4444.ccd", 
"5555.ccd", 
"6666.ccd", 
"6969.ccd", 
"8888.ccd", 
"9999.ccd", 
"andrew.ccd", 
"apartment.ccd", 
"hotel.ccd", 
"house.ccd", 
"singh.ccd", 
"supercar.ccd", 
"superyacht.ccd", 
"team.ccd", 
"trump2024.ccd", 
"wine.ccd", 
"zhang.ccd", 
"012.ccd", 
"013.ccd", 
"014.ccd", 
"015.ccd", 
"016.ccd", 
"017.ccd", 
"018.ccd", 
"019.ccd", 
"021.ccd", 
"022.ccd", 
"023.ccd", 
"024.ccd", 
"025.ccd", 
"026.ccd", 
"027.ccd", 
"028.ccd", 
"029.ccd", 
"222.ccd", 
"333.ccd", 
"444.ccd", 
"555.ccd", 
"666.ccd", 
"888.ccd", 
"999.ccd", 
"amazon.ccd", 
"anon.ccd", 
"apple.ccd", 
"bank.ccd", 
"ced.ccd", 
"dan.ccd", 
"disney.ccd", 
"fed.ccd", 
"google.ccd", 
"himi.ccd", 
"iuliia.ccd", 
"kiss.ccd", 
"lego.ccd", 
"loan.ccd", 
"nba.ccd", 
"noob.ccd", 
"pixar.ccd", 
"punk.ccd", 
"tim.ccd", 
"usa.ccd", 
"Joha.ccd", 
"Ferdi.ccd", 
"groot.ccd", 
"James.ccd", 
"minrie.ccd", 
"0003.ccd", 
"aaa.ccd", 
"adam.ccd", 
"Amida.ccd", 
"baha.ccd", 
"Explorer.ccd", 
"Jek.ccd", 
"Nazo.ccd", 
"Sander.ccd", 
"Sedef.ccd", 
"uzb.ccd", 
"777.ccd", 
"ag.ccd", 
"agbona.ccd", 
"Amir.ccd", 
"Declan.ccd", 
"Ireland.ccd", 
"jek.ccd", 
"LRS.ccd", 
"nazo.ccd", 
"Rafun.ccd", 
"Turkiye.ccd", 
"Degen.ccd", 
"KK.ccd", 
"Krpan.ccd", 
"swap.ccd", 
"binance.ccd", 
"blackjack.ccd", 
"darkmarket.ccd", 
"exchange.ccd", 
"ftx.ccd", 
"northstake.ccd", 
"openmarket.ccd", 
"payment.ccd", 
"tickets.ccd", 
"trading.ccd", 
"banking.ccd", 
"ethereum.ccd", 
"identity.ccd", 
"insurance.ccd", 
"jackpot.ccd", 
"lottery.ccd", 
"messenger.ccd", 
"openexchange.ccd", 
"pharmacy.ccd", 
"registry.ccd", 
"wallet.ccd", 
"wallets.ccd", 
"bitcoin.ccd", 
"blackrock.ccd", 
"capital.ccd", 
"contracts.ccd", 
"currency.ccd", 
"entertainment.ccd", 
"gambling.ccd", 
"hosting.ccd", 
"imtoken.ccd", 
"mywallet.ccd", 
"network.ccd", 
"official.ccd", 
"qianbao.ccd", 
"service.ccd", 
"shopping.ccd", 
"starbucks.ccd", 
"contract.ccd", 
"investments.ccd", 
"affiliate.ccd", 
"america.ccd", 
"charity.ccd", 
"domains.ccd", 
"donation.ccd", 
"fidelity.ccd", 
"marketing.ccd", 
"progressive.ccd", 
"shanghai.ccd", 
"betting.ccd", 
"download.ccd", 
"lending.ccd", 
"linkedin.ccd", 
"register.ccd", 
"verified.ccd", 
"careers.ccd", 
"product.ccd", 
"singapore.ccd", 
"toshiba.ccd", 
"barclays.ccd", 
"express.ccd", 
"healthcare.ccd", 
"alibaba.ccd", 
"apartments.ccd", 
"california.ccd", 
"coinbase.ccd", 
"crowdfund.ccd", 
"fashion.ccd", 
"forward.ccd", 
"glencore.ccd", 
"mortgage.ccd", 
"program.ccd", 
"booking.ccd", 
"foundation.ccd", 
"pornhub.ccd", 
"samsung.ccd", 
"bloomberg.ccd", 
"lasvegas.ccd", 
"parking.ccd", 
"realestate.ccd", 
"warranty.ccd", 
"arbitration.ccd", 
"americanexpress.ccd", 
"flights.ccd", 
"football.ccd", 
"forsale.ccd", 
"freelancer.ccd", 
"marijuana.ccd", 
"payments.ccd", 
"pokerstars.ccd", 
"university.ccd", 
"losangeles.ccd", 
"nationwide.ccd", 
"powerball.ccd", 
"contact.ccd", 
"deposit.ccd", 
"toronto.ccd", 
"advertise.ccd", 
"bitfinex.ccd", 
"business.ccd", 
"casino.ccd", 
"company.ccd", 
"consenlabs.ccd", 
"dropbox.ccd", 
"firstblood.ccd", 
"flowers.ccd", 
"philips.ccd", 
"ratings.ccd", 
"services.ccd", 
"silkroad.ccd", 
"slotmachine.ccd", 
"sportsbet.ccd", 
"ufc.ccd", 
"anonymous.ccd", 
"college.ccd", 
"florida.ccd", 
"harvard.ccd", 
"internet.ccd", 
"jpmorganchase.ccd", 
"satoshi.ccd", 
"vancouver.ccd", 
"volkswagen.ccd", 
"allstate.ccd", 
"bitflyer.ccd", 
"database.ccd", 
"directory.ccd", 
"formula1.ccd", 
"jpmorgan.ccd", 
"kickstarter.ccd", 
"mckinsey.ccd", 
"purchase.ccd", 
"santander.ccd", 
"stablecoin.ccd", 
"mediator.ccd", 
"newyork.ccd", 
"statestreet.ccd", 
"superbowl.ccd", 
"android.ccd", 
"creditsuisse.ccd", 
"deloitte.ccd", 
"journal.ccd", 
"michelin.ccd", 
"olympics.ccd", 
"prudential.ccd", 
"softbank.ccd", 
"testing.ccd", 
"websites.ccd", 
"address.ccd", 
"alcohol.ccd", 
"authenticate.ccd", 
"bnpparibas.ccd", 
"charities.ccd", 
"ethwallet.ccd", 
"hitachi.ccd", 
"hongkong.ccd", 
"investment.ccd", 
"invoicing.ccd", 
"mailbox.ccd", 
"medicare.ccd", 
"military.ccd", 
"mitsubishi.ccd", 
"organic.ccd", 
"payroll.ccd", 
"starwars.ccd", 
"statefarm.ccd", 
"washington.ccd", 
"wedding.ccd", 
"wellsfargo.ccd", 
"example.ccd", 
"louisvuitton.ccd", 
"portfolio.ccd", 
"process.ccd", 
"science.ccd", 
"societegenerale.ccd", 
"sportsbetting.ccd", 
"travelinsurance.ccd", 
"hedgefund.ccd", 
"bestbuy.ccd", 
"chevrolet.ccd", 
"rentals.ccd", 
"robotics.ccd", 
"wallstreet.ccd", 
"weather.ccd", 
"123456789.ccd", 
"baseball.ccd", 
"brainbot.ccd", 
"community.ccd", 
"esports.ccd", 
"mcdonalds.ccd", 
"cbsnews.ccd", 
"escorts.ccd", 
"kucoin.ccd", 
"location.ccd", 
"original.ccd", 
"siliconvalley.ccd", 
"stabletoken.ccd", 
"tencent.ccd", 
"westpac.ccd", 
"auctions.ccd", 
"blockchaincapital.ccd", 
"cannabis.ccd", 
"certificate.ccd", 
"coupons.ccd", 
"crowdsale.ccd", 
"cryptocurrency.ccd", 
"delivery.ccd", 
"enterprise.ccd", 
"freemarket.ccd", 
"government.ccd", 
"passport.ccd", 
"records.ccd", 
"reputation.ccd", 
"savings.ccd", 
"smartcontract.ccd", 
"standard.ccd", 
"verizon.ccd", 
"washingtonpost.ccd", 
"website.ccd", 
"academy.ccd", 
"beautiful.ccd", 
"credits.ccd", 
"darknet.ccd", 
"doctors.ccd", 
"draftkings.ccd", 
"guarantee.ccd", 
"investor.ccd", 
"invoice.ccd", 
"microsoft.ccd", 
"netflix.ccd", 
"onlinecasino.ccd", 
"photography.ccd", 
"private.ccd", 
"reserve.ccd", 
"technology.ccd", 
"ferrari.ccd", 
"financialservices.ccd", 
"platform.ccd", 
"realtor.ccd", 
"bodybuilding.ccd", 
"dealership.ccd", 
"democrat.ccd", 
"founders.ccd", 
"manager.ccd", 
"space7.ccd", 
"spaceseven.ccd", 
"supplychain.ccd", 
"xvideos.ccd", 
"blackstone.ccd", 
"brokerage.ccd", 
"cartier.ccd", 
"clothes.ccd", 
"cryptocoins.ccd", 
"emirates.ccd", 
"fundraising.ccd", 
"halliburton.ccd", 
"leasing.ccd", 
"options.ccd", 
"privacy.ccd", 
"project.ccd", 
"restaurant.ccd", 
"supermarket.ccd", 
"automobile.ccd", 
"chinese.ccd", 
"contest.ccd", 
"dominos.ccd", 
"fintech.ccd", 
"protocol.ccd", 
"reservation.ccd", 
"storage.ccd", 
"windows.ccd", 
"youtube.ccd", 
"commodity.ccd", 
"darkweb.ccd", 
"gofundme.ccd", 
"loyalty.ccd", 
"reviews.ccd", 
"architect.ccd", 
"calendar.ccd", 
"cycling.ccd", 
"giftcards.ccd", 
"logistics.ccd", 
"quickbet.ccd", 
"redbull.ccd", 
"rentacar.ccd", 
"sothebys.ccd", 
"advertising.ccd", 
"amd.ccd", 
"american.ccd", 
"automation.ccd", 
"copyright.ccd", 
"cryptocoin.ccd", 
"employment.ccd", 
"flipkart.ccd", 
"mastercard.ccd", 
"stockmarket.ccd", 
"twitter.ccd", 
"worldbank.ccd", 
"amy.ccd", 
"ass.ccd", 
"bmw.ccd", 
"btc.ccd", 
"car.ccd", 
"cat.ccd", 
"com.ccd", 
"cox.ccd", 
"cyb.ccd", 
"dai.ccd", 
"dao.ccd", 
"document.ccd", 
"eth.ccd", 
"god.ccd", 
"sex.ccd", 
"stakepool.ccd", 
"username.ccd", 
"visa.ccd", 
"electricity.ccd", 
"victory.ccd", 
"biology.ccd", 
"christian.ccd", 
"coca-cola.ccd", 
"crowdfunding.ccd", 
"muslim.ccd", 
"playboy.ccd", 
"socialmedia.ccd", 
"support.ccd", 
"translator.ccd", 
"designer.ccd", 
"ownership.ccd", 
"promotion.ccd", 
"shopify.ccd", 
"economy.ccd", 
"entrepreneur.ccd", 
"hollywood.ccd", 
"instagram.ccd", 
"unitedstates.ccd", 
"blogger.ccd", 
"builder.ccd", 
"digitalmoney.ccd", 
"dollars.ccd", 
"fitness.ccd", 
"medicine.ccd", 
"organization.ccd", 
"programmer.ccd", 
"rewards.ccd", 
"smartmoney.ccd", 
"traveler.ccd", 
"12345.ccd", 
"247.ccd", 
"Buhara.ccd", 
"dappstore.ccd", 
"diamond.ccd", 
"digital.ccd", 
"dubai.ccd", 
"Kim.ccd", 
"license.ccd", 
"listing.ccd", 
"marketplace.ccd", 
"Buhariy.ccd", 
"buhariyfoundation.ccd", 
"Korea.ccd", 
"0.ccd", 
"Automaker.ccd", 
"Autoverse.ccd", 
"China.ccd", 
"Ukraine.ccd", 
"vc.ccd", 
"xx.ccd", 
"auction.ccd", 
"Bictory.ccd", 
"Bictorydao.ccd", 
"bictorydex.ccd", 
"bictoryex.ccd", 
"bictoryexchange.ccd", 
"bictoryfinance.ccd", 
"bictorygame.ccd", 
"bictorygames.ccd", 
"bictoryland.ccd", 
"bictorynft.ccd", 
"cex.ccd", 
"dex.ccd", 
"dfc.ccd", 
"efc.ccd", 
"finance.ccd", 
"metaverse.ccd", 
"web3.ccd", 
"xxx.ccd", 
"avax.ccd", 
"cns.ccd", 
"landproperty.ccd", 
"property.ccd", 
"startup.ccd", 
"sportcar.ccd", 
"111.ccd", 
"dapp.ccd", 
"gold.ccd", 
"token.ccd", 
"trade.ccd", 
"-.ccd", 
"::DOMAIN::.ccd", 
"!.ccd", 
"(.ccd", 
").ccd", 
"@.ccd", 
"*.ccd", 
"#.ccd", 
"%.ccd", 
"^.ccd", 
"+.ccd", 
"$.ccd", 
"aa.ccd", 
"art.ccd", 
"cc.ccd", 
"ccc.ccd", 
"coffee.ccd", 
"#ERROR!", 
"?.ccd", 
"000.ccd", 
"7777.ccd", 
"bictorian.ccd", 
"borrow.ccd", 
"honda.ccd", 
"job.ccd", 
"jobs.ccd", 
"kia.ccd", 
"lend.ccd", 
"mercedesbenz.ccd", 
"Toyota.ccd", 
"0000.ccd", 
"allah.ccd", 
"kuwait.ccd", 
"Portugal.ccd", 
"qatar.ccd", 
"uae.ccd", 
"123.ccd", 
"abc.ccd", 
"bictorytoken.ccd", 
"blockchain.ccd", 
"BT.ccd", 
"cars.ccd", 
"cbdc.ccd", 
"ccdstake.ccd", 
"earn.ccd", 
"game.ccd", 
"music.ccd", 
"Stake.ccd", 
"stockexchange.ccd", 
"tokensale.ccd", 
"money.ccd", 
"defi.ccd", 
"Hello.ccd", 
"Salam.ccd", 
"terrestria.ccd", 
"musicnft.ccd", 
"01.ccd", 
"founder.ccd", 
"001.ccd", 
"003.ccd", 
"007.ccd", 
"07.ccd", 
"010.ccd", 
"011.ccd", 
"020.ccd", 
"030.ccd", 
"050.ccd", 
"060.ccd", 
"070.ccd", 
"090.ccd", 
"beer.ccd", 
"crypto.ccd", 
"vvv.ccd", 
"www.ccd", 
"yyy.ccd", 
"zzz.ccd", 
"1.ccd", 
"2.ccd", 
"3.ccd", 
"4.ccd", 
"5.ccd", 
"6.ccd", 
"7.ccd", 
"8.ccd", 
"9.ccd", 
"A.ccd", 
"B.ccd", 
"C.ccd", 
"D.ccd", 
"E.ccd", 
"F.ccd", 
"f1.ccd", 
"G.ccd", 
"games.ccd", 
"geely.ccd", 
"nft.ccd", 
"play.ccd", 
"rent.ccd", 
"salah.ccd", 
"saudiarabia.ccd", 
"terrestiax.ccd", 
"tesla.ccd", 
"tip.ccd", 
"H.ccd", 
"I.ccd", 
"J.ccd", 
"K.ccd", 
"L.ccd", 
"M.ccd", 
"N.ccd", 
"O.ccd", 
"P.ccd", 
"Q.ccd", 
"R.ccd", 
"S.ccd", 
"concordium.ccd", 
"T.ccd", 
"U.ccd", 
"V.ccd", 
"W.ccd", 
"X.ccd", 
"Y.ccd", 
"Z.ccd", 
"ccd.ccd", 
"concordex.ccd", 
"concordium1.ccd", 
"concordium2.ccd", 
"ConcordiumAg.ccd", 
"concordiumfoundation.ccd", 
"ConcordiumResearch.ccd", 
"conucopia.ccd", 
"cornucopia.ccd", 
"cornucopiabridge.ccd", 
"COBRA.ccd", 
"concordiumwallet.ccd", 
"events.ccd", 
"fees.ccd", 
"ID.ccd", 
"staking.ccd", 
"lfs.ccd", 
"lone.ccd", 
"lonefonss.ccd", 
"lars.ccd", 
"LarsSeier.ccd", 
"hauglund.ccd", 
"ikea.ccd", 
"LarsSeierChristensen.ccd", 
"volvo.ccd", 
"airdrop.ccd", 
"grants.ccd", 
"treasury.ccd", 
"🐱🐱.ccd", 
"👎.ccd", 
"💅🏿.ccd", 
"📃👐.ccd", 
"🖖🏼.ccd", 
"🤏🏼.ccd", 
"🤛🏾.ccd", 
"🤴🏾.ccd", 
"🦅.ccd", 
"🧑‍🍳.ccd", 
"🧒🏿.ccd", 
"😉.ccd", 
"amalia.ccd", 
"conor.ccd", 
"daniel.ccd", 
"DrDoom.ccd", 
"hodl.ccd", 
"Lotus.ccd", 
"lynk::DOMAIN::co.ccd", 
"marc.ccd", 
"maria.ccd", 
"mariaamalia.ccd", 
"mepelch.ccd", 
"pablo.ccd", 
"pelch.ccd", 
"philip.ccd", 
"Polestar.ccd", 
"ringland .ccd", 
"thelook.ccd", 
"vedel.ccd", 
"vero24.ccd", 
"victor.ccd", 
"👮🏿.ccd", 
"🤦🏾.ccd", 
"✋🏼.ccd", 
"👏🏿.ccd", 
"👐🏾.ccd", 
"👦🏾.ccd", 
"🖐🏽.ccd", 
"🧒🏾.ccd", 
"😜.ccd", 
"👈🏾.ccd", 
"🤷🏽.ccd", 
"🧑🏾.ccd", 
"⛎⛎⛎.ccd", 
"🍁🍁🍁.ccd", 
"👂🏽.ccd", 
"👃🏽.ccd", 
"👇🏾.ccd", 
"👧🏾.ccd", 
"💛💛.ccd", 
"🤨.ccd", 
"😒.ccd", 
"😔.ccd", 
"😕.ccd", 
"😗.ccd", 
"😙.ccd", 
"😛.ccd", 
"😞.ccd", 
"😟.ccd", 
"🙎🏻.ccd", 
"🚀🙏🏿.ccd", 
"🌈.ccd", 
"👈🏽.ccd", 
"👉🏿.ccd", 
"👾👘.ccd", 
"💺.ccd", 
"🤚🏾.ccd", 
"🚀.ccd", 
"👱🏾.ccd", 
"💇🏼.ccd", 
"🤵🏾.ccd", 
"🦸🏿.ccd", 
"🙇🏼.ccd", 
"⛏.ccd", 
"✨.ccd", 
"🌇.ccd", 
"🍂🍂🍂.ccd", 
"👨‍🦲.ccd", 
"👩‍🦲.ccd", 
"👳🏽‍♀.ccd", 
"🕴🏾.ccd", 
"🧑🏾‍🎤.ccd", 
"🧙🏾‍♀.ccd", 
"👉.ccd", 
"👩🏿‍🏫.ccd", 
"👩‍🔬.ccd", 
"👰🏼‍♀.ccd", 
"🔫.ccd", 
"🦸🏾‍♀.ccd", 
"🧏‍♂.ccd", 
"🧗🏾‍♀.ccd", 
"🙍‍♀.ccd", 
"🎨.ccd", 
"👩🏿‍❤‍👩🏼.ccd", 
"💰.ccd", 
"🙋🏿‍♀.ccd", 
"⚡.ccd", 
"💲.ccd", 
"a.ccd", 
"amida.ccd", 
"amir.ccd", 
"automaker.ccd", 
"autoverse.ccd", 
"b.ccd", 
"bictory.ccd", 
"bictorydao.ccd", 
"bt.ccd", 
"buhara.ccd", 
"buhariy.ccd", 
"c.ccd", 
"china.ccd", 
"cobra.ccd", 
"concordiumag.ccd", 
"concordiumresearch.ccd", 
"d.ccd", 
"declan.ccd", 
"degen.ccd", 
"drdoom.ccd", 
"e.ccd", 
"explorer.ccd", 
"f.ccd", 
"ferdi.ccd", 
"g.ccd", 
"h.ccd", 
"hello.ccd", 
"i.ccd", 
"id.ccd", 
"ireland.ccd", 
"j.ccd", 
"james.ccd", 
"joha.ccd", 
"k.ccd", 
"kim.ccd", 
"kk.ccd", 
"korea.ccd", 
"krpan.ccd", 
"l.ccd", 
"larsseier.ccd", 
"lrs.ccd", 
"larsseierchristensen.ccd", 
"lotus.ccd", 
"m.ccd", 
"n.ccd", 
"o.ccd", 
"p.ccd", 
"polestar.ccd", 
"portugal.ccd", 
"q.ccd", 
"r.ccd", 
"rafun.ccd", 
"s.ccd", 
"salam.ccd", 
"sander.ccd", 
"sedef.ccd", 
"stake.ccd", 
"t.ccd", 
"toyota.ccd", 
"turkiye.ccd", 
"u.ccd", 
"ukraine.ccd", 
"v.ccd", 
"w.ccd", 
"x.ccd", 
"y.ccd", 
"z.ccd", 
"ringland.ccd", 
"testtoken1.ccd", 
"testtoken2.ccd", 
"lulia.ccd", 
"mark.ccd", 
"mohsen.ccd", 
"ahmad.ccd", 
"dilshod.ccd", 
"majid.ccd", 
"mikhail.ccd", 
"shaha.ccd", 
"annep.ccd", 
"sulton.ccd", 
"anne.ccd", 
"tarun.ccd", 
"anfid.ccd", 
"oaowfudtnvoawudh.ccd", 
"khalikov.ccd", 
"shhahahahaha.ccd", 
"neversendingfundstothewrongwalletagain.ccd", 
"seiercapital.ccd", 
"nonameslefttouse.ccd", 
"beejay.ccd", 
"١١١.ccd", 
"isthismy.ccd", 
"八八八.ccd", 
"11111.ccd", 
"liberty.ccd", 
"moonboy.ccd", 
"beejaz.ccd", 
"donate.ccd", 
"baker.ccd", 
"rasmus.ccd", 
"volvocars.ccd", 
"٧٨٦.ccd", 
"snook.ccd", 
"ilove.ccd", 
"switchere.ccd", 
"thomas.ccd", 
"william.ccd", 
"artpusher.ccd", 
"maersk.ccd", 
"voehlert.ccd", 
"wagmi.ccd", 
"carbotanix.ccd", 
"ticket.ccd", 
"morten.ccd", 
"gameslab.ccd", 
"icloud.ccd", 
"sunnat.ccd", 
"pangoli.ccd", 
"cryptogammon.ccd", 
"türkiye.ccd", 
"concordiumnameservice.ccd", 
"walter.ccd", 
"marcus.ccd", 
"patricia.ccd", 
"ellie.ccd", 
"asboe.ccd", 
"tokens.ccd", 
"cryptogames.ccd", 
"cnsdomains.ccd", 
"elanor.ccd", 
"malte.ccd", 
"akasdemedici.ccd", 
"cecillia.ccd", 
"denmark.ccd", 
"patrick.ccd", 
"cryptochess.ccd", 
"ccddomains.ccd", 
"cryptoludo.ccd", 
"online.ccd", 
"ccdtoken.ccd", 
"hotels.ccd", 
"cecilia.ccd", 
"cryptopoker.ccd", 
"saxo.ccd", 
"helpdesk.ccd", 
"swiss.ccd", 
"cryptotoken.ccd", 
"onlinegames.ccd", 
"mercedes-benz.ccd", 
"domain.ccd", 
"torben.ccd", 
"00.ccd", 
"coincheck.ccd", 
"holger.ccd", 
"kaaber.ccd", 
"ripple.ccd", 
"putin.ccd", 
"rolls-royce.ccd", 
"news.ccd", 
"solana.ccd", 
"elonmusk.ccd", 
"porsche.ccd", 
"rugpull.ccd", 
"market.ccd", 
"dapps.ccd", 
"exchanges.ccd", 
"gamelab.ccd", 
"launchpad.ccd", 
"häcki.ccd", 
"collection.ccd", 
"cryptos.ccd", 
"polygon.ccd", 
"adidas.ccd", 
"rugmedaddy.ccd", 
"1layer.ccd", 
"firstlayer.ccd", 
"firstlayerid.ccd", 
"kingrugger.ccd", 
"1-layer-id.ccd", 
"lerche.ccd", 
"first-layer-id.ccd", 
"cash.ccd", 
"worldcup.ccd", 
"rajesh.ccd", 
"gucci.ccd", 
"chanel.ccd", 
"invest.ccd", 
"meta.ccd", 
"innovation.ccd", 
"amdax.ccd", 
"chf.ccd", 
"stakely.ccd", 
"suisse.ccd", 
"blackpink.ccd", 
"ledger.ccd", 
"notabene.ccd", 
"vodafone.ccd", 
"overlay.ccd", 
"nike.ccd", 
"overlaydao.ccd", 
"mtn.ccd", 
"europe.ccd", 
"newbalance.ccd", 
"lupin.ccd", 
"exxonmobil.ccd", 
"gazprom.ccd", 
"metamask.ccd", 
"stisen.ccd", 
"microstrategy.ccd", 
"spacex.ccd", 
"nasdaq.ccd", 
"citigroup.ccd", 
"420.ccd", 
"goldmansachs.ccd", 
"staker.ccd", 
"idlayer.ccd", 
"artfan.ccd", 
"fabcafe.ccd", 
"paypal.ccd", 
"id-layer.ccd", 
"annemazing.ccd", 
"ccdscan.ccd", 
"neukom.ccd", 
"balenciaga.ccd", 
"prada.ccd", 
"dani.ccd", 
"porn.ccd", 
"69.ccd", 
"degens.ccd", 
"mexc.ccd", 
"normies.ccd", 
"ascendex.ccd", 
"coinmarketcap.ccd", 
"once_upon_a_time.ccd", 
"melvin.ccd", 
"terrestriax.ccd", 
"sexy.ccd", 
"walid.ccd", 
"amdaxcapital.ccd", 
"charlotte.ccd", 
"livejasmin.ccd", 
"webcam.ccd", 
"elnet.ccd", 
"redtube.ccd", 
"christopher.ccd", 
"energinet.ccd", 
"matteo.ccd", 
"seier.ccd", 
"julian.ccd", 
"abudhabi.ccd", 
"dxb.ccd", 
"cisco.ccd", 
"thijs.ccd", 
"thijslooten.ccd", 
"mcdonald's.ccd", 
"carlsberg.ccd", 
"dickbutt.ccd", 
"cryptodickbutt.ccd", 
"cryptopunks.ccd", 
"funding.ccd", 
"artist.ccd", 
"matthijslooten.ccd", 
"usdc.ccd", 
"usdt.ccd", 
"cusd.ccd", 
"usd.ccd", 
"saxobank.ccd", 
"huobi.ccd", 
"bithumb.ccd", 
"euroc.ccd", 
"busd.ccd", 
"amsterdam.ccd", 
"wholesale.ccd", 
"projects.ccd", 
"burger.ccd", 
"influencer.ccd", 
"blockspot.ccd", 
"dennis.ccd", 
"nordin.ccd", 
"dn.ccd", 
"hermes.ccd", 
"fendi.ccd", 
"eminem.ccd", 
"gaming.ccd", 
"peter.ccd", 
"88.ccd", 
"gamble.ccd", 
"burgerking.ccd", 
"klarity.ccd", 
"matrix.ccd", 
"wakaflocka.ccd", 
"undefined.ccd", 
"redcross.ccd", 
"crystalhefner.ccd", 
"me.ccd", 
"africa.ccd", 
"african.ccd", 
"porno.ccd", 
"realmosesjames.ccd", 
"zaumka.ccd", 
"tschudid.ccd", 
"valentino.ccd", 
"phygital.ccd", 
"michiel.ccd", 
"mibdk.ccd", 
"theceo.ccd", 
"linked.ccd", 
"royale.ccd", 
"ab.ccd", 
"pixpel.ccd", 
"marius.ccd", 
"sirdrymartini.ccd", 
"queen.ccd", 
"xxxxxx.ccd", 
"robert.ccd", 
"terranea.ccd", 
"lamborghini.ccd", 
"mrmoney.ccd", 
"rolex.ccd", 
"fireblocks.ccd", 
"bictorinyan.ccd", 
"copper.ccd", 
"bitvavo.ccd", 
"mrbeast.ccd", 
"danskebank.ccd", 
"buymydomainplz.ccd", 
"manunited.ccd", 
"cocacola.ccd", 
"heineken.ccd", 
"discord.ccd", 
"vitalik.ccd", 
"loans.ccd", 
"chicago.ccd", 
"travala.ccd", 
"transfer.ccd", 
"whale.ccd", 
"playbase.ccd", 
"hypery.ccd", 
"beatoken.ccd", 
"matic.ccd", 
"harry.ccd", 
"stepn.ccd", 
"tokyo.ccd", 
"crosslink.ccd", 
"justeat.ccd", 
"bybit.ccd", 
"takeaway.ccd", 
"lonefonssschroder.ccd", 
"mariapelch.ccd", 
"kårekjelstrøm.ccd", 
"xrp.ccd", 
"mrwonderful.ccd", 
"thc.ccd", 
"omega.ccd", 
"fckøbenhavn.ccd", 
"lacoste.ccd", 
"ralph.ccd", 
"whisky.ccd", 
"cafédanturèll.ccd", 
"champagne.ccd", 
"fck.ccd", 
"vitalikbuterin.ccd", 
"omegawatches.ccd", 
"patek.ccd", 
"versace.ccd", 
"sephora.ccd", 
"audemarspiguet.ccd", 
"oxford.ccd", 
"concordiam.ccd", 
"lonefønssschrøder.ccd", 
"cryptocasino.ccd", 
"swvl.ccd", 
"haugstrup.ccd", 
"vietnam.ccd", 
"88888888.ccd", 
"cafedanturell.ccd", 
"digisign.ccd", 
"88888.ccd", 
"99999.ccd", 
"sami.ccd", 
"george.ccd", 
"elon-musk.ccd", 
"tenko.ccd", 
"oriental.ccd", 
"batur.ccd", 
"flemming.ccd", 
"thailand.ccd", 
"christies.ccd", 
"serum8.ccd", 
"fabian.ccd", 
"milbo.ccd", 
"geranium.ccd", 
"hummel.ccd", 
"tuborg.ccd", 
"❤️.ccd", 
"flammen.ccd", 
"fitnessworld.ccd", 
"11.ccd", 
"store.ccd", 
"concordiumisgoinggreat.ccd", 
"verify.ccd", 
"ccverify.ccd", 
"co2.ccd", 
"john.ccd", 
"the.ccd", 
"app.ccd", 
"number.ccd", 
"pokemon.ccd", 
"lunar.ccd", 
"markus.ccd", 
"verse.ccd", 
"☀️.ccd", 
"space.ccd", 
"craze2007.ccd", 
"iphone.ccd", 
"thefounder.ccd", 
"cryptox.ccd", 
"silentk.ccd", 
"11:11.ccd", 
"aesirx.ccd", 
"mariaeisnerpelch.ccd", 
"deustchetelekon.ccd", 
"lenovo.ccd", 
"infinity.ccd", 
"qualcomm.ccd", 
"virtualware.ccd", 
"dreamlandxr.ccd", 
"plugandplay.ccd", 
"accenture.ccd", 
"metapolis.ccd", 
"netflix+.ccd", 
"netflixplus.ccd", 
"spotify.ccd", 
"qatarairways.ccd", 
"konami.ccd", 
"nintendo.ccd", 
"adyen.ccd", 
"oracle.ccd", 
"fibree.ccd", 
"rakuten.ccd", 
"️.ccd", 
"00000.ccd", 
"77777.ccd", 
"22222.ccd", 
"tacans.ccd", 
"racecar.ccd", 
"marvel.ccd", 
"avatar.ccd", 
"★️.ccd", 
"brand.ccd", 
"888888.ccd", 
"etoro.ccd", 
"bitbasel.ccd", 
"opensea.ccd", 
"33333.ccd", 
"44444.ccd", 
"55555.ccd", 
"66666.ccd", 
"coin.ccd", 
"pay.ccd", 
"investing.ccd", 
"buy.ccd", 
"boredapeyachtclub.ccd", 
"10kclub.ccd", 
"proxid.ccd", 
"99.ccd", 
"🍔_king.ccd", 
"apple🍏.ccd", 
"☕️coffee.ccd", 
"denmark🇩🇰.ccd", 
"swiss🇨🇭.ccd", 
"france🇫🇷.ccd", 
"italy🇮🇹.ccd", 
"saudiarabia🇸🇦.ccd", 
"card.ccd", 
"pikachu.ccd", 
"unitedkingdom.ccd", 
"london.ccd", 
"universal.ccd", 
"ing.ccd", 
"radiohead.ccd", 
"UNIQLO.ccd", 
"livecasino.ccd", 
"davincij15.ccd", 
"yoshinori.ccd", 
"yumon.ccd", 
"animocabrands.ccd", 
"whatnot.ccd", 
"joh-enschede.ccd", 
"t-mobile.ccd", 
"turuu.ccd", 
"bjoern.ccd", 
"antminer.ccd", 
"bitmain.ccd", 
"ricardo.ccd", 
"goranov.ccd", 
"asia.ccd", 
"travel.ccd", 
"amdax1.ccd", 
"onlyfans.ccd", 
"master.ccd", 
"uber.ccd", 
"lfg.ccd", 
"ex.ccd", 
"fomo.ccd", 
"pump.ccd", 
"domainame.ccd", 
"therichdad.ccd", 
"myamdax.ccd", 
"dojacat.ccd", 
"annefab.ccd", 
"nestle.ccd", 
"exarta.ccd", 
"index.ccd", 
"1337.ccd", 
"bridge.ccd", 
"esport.ccd", 
"lumen.ccd", 
"paramount.ccd", 
"gamefi.ccd", 
"gushansen.ccd", 
"thegreatdane.ccd", 
"mancity.ccd", 
"arsenal.ccd", 
"lambo.ccd", 
"kristina.ccd", 
"hokkfinance.ccd", 
"نيوم.ccd", 
"00:00.ccd", 
"gustav.ccd", 
"cryptobridge.ccd", 
"bankingcircle.ccd", 
"prime.ccd", 
"kontango.ccd", 
"@metaverse.ccd", 
"roblox.ccd", 
"phil116.ccd", 
"sony.ccd", 
"sak.ccd", 
"reddit.ccd", 
"fortnite.ccd", 
"unilever.ccd", 
"johnson&johnson.ccd", 
"bigbag.ccd", 
"ooo.ccd", 
"neom.ccd", 
"sellix.ccd", 
"sellixio.ccd", 
"midnightrain.ccd", 
"renderlounge.ccd", 
"journey.ccd", 
"mads.ccd", 
"play2earn.ccd", 
"🎃.ccd", 
"divergent3d.ccd", 
"telegram.ccd", 
"cosmos.ccd", 
"angel.ccd", 
"alex.ccd", 
"intel.ccd", 
"konstantin.ccd", 
"euro.ccd", 
"portal.ccd", 
"fund.ccd", 
"smart.ccd", 
"forge.ccd", 
"altcoin.ccd", 
"philotuut.ccd", 
"anarchyhasnogods.ccd", 
"funds.ccd", 
"account.ccd", 
"freepalestine.ccd", 
"regdefi.ccd", 
"regfi.ccd", 
"refi.ccd", 
"sydbank.ccd", 
"yanick.ccd", 
"baking.ccd", 
"chatgpt.ccd", 
"frontier.ccd", 
"climafi.ccd", 
"energinetdk.ccd",
"DjookyX.ccd",
"Beepo.ccd",
"Subtonic.ccd",
"HouseofRare.ccd",
"HoR.ccd",
"Digitalsocial.ccd",
"DigitalSocialID.ccd",
"Versus.ccd",
"Next-Gen.ccd",
"NextGen.ccd",
"Phi.ccd",
"IDsure.ccd",
"Hypery.ccd",
"ManaGames.ccd",
"BankofMemories.ccd",
"BoM.ccd",
"Carbotanix.ccd",
"Sonio.ccd",
"Overlay.ccd",
"Xone.ccd",
"SignaX.ccd",
"LC1C.ccd",
"FindConnect.ccd",
"Aqualibre.ccd",
"MeProtocol.ccd",
"Ibexus.ccd",
"SharingClub.ccd",
"Orpiva.ccd",
"Cryptochefs.ccd",
"SHC.ccd",
"HousingtheFuture.ccd",
"Masttia.ccd",
"MasttiaMetaverse.ccd",
"Subtonic.ccd",
"HouseofRare.ccd",
"HouseofRareNFT.ccd",
"Edubuk.ccd",
"IDsure.ccd",
"2021ai.ccd",
"Golisto.ccd",
"Wikifactory.ccd",
"Zenseact.ccd",
"GamersGold.ccd",
"Beatoken.ccd",
"BitNordic.ccd",
"Saxon.ccd",
"VirtualHive.ccd",
"Arabella.ccd",
"Akash.ccd",
"Openbusinesscouncil.ccd",
"OBC.ccd",
"AkashNetwork.ccd",
"Ztudium.ccd",
"Quid.ccd",
"Myrmidon.ccd",
"Blocktech.ccd",
"OpenBiSea.ccd",
"ChessChamps.ccd",
"MotoDex.ccd",
"Quidli.ccd",
"ProvenanceTags.ccd",
"Arivu.ccd",
"ArivuLabs.ccd",
"Wert.ccd",
"Mojomoto.ccd",
"Impero.ccd",
"cryptoroast.ccd",
"xportal.ccd"
];
*/

let _WALLET_INFO:any={
	provider:null,
	account:'',
	genesisHash:''
	
};
function SearchInput() {
	const navigate=useNavigate();
	const {walletInfo,updateWalletInfo} = useContext(UserContext);
	const [inputSearch,setInputSearch]=useState<string>("");
	const [domain,setDomain]=useState<string>("");
	const [availableStatus,setAvailableStatus]=useState<string>("0");
	const [currentAccount,setCurrentAccount]=useState<any>("");
	const [reqWait,setReqWait]=useState<boolean>(false);
	
	useEffect(()=>{
		_WALLET_INFO.provider=walletInfo.provider;
		_WALLET_INFO.account=walletInfo.account;
		_WALLET_INFO.genesisHash=walletInfo.genesisHash;
		setCurrentAccount(walletInfo.account);
	},[walletInfo]);
	const isDomainExist=async(domain:string)=>{
		try{
			if(pre_domain.indexOf(domain.toLocaleLowerCase())>=0){ return(true);}
			let _domain={ domain:domain};
			const param:any = serializeUpdateContractParameters('ccd','getDomain',_domain,toBuffer(RAW_SCHEMA, 'base64'));
			console.log(_WALLET_INFO);
			console.log(_WALLET_INFO.provider);
			console.log(_WALLET_INFO.provider.getGrpcClient());
			let returnValue:any=await _WALLET_INFO.provider.getGrpcClient().invokeContract({
				contract: { index: BigInt(CCD_INFO.CONTRACT_INDEX), subindex: BigInt(0) },
				method: 'ccd.getDomain',
				parameter:param
			});	
			if (returnValue && returnValue.tag === 'success' && returnValue.returnValue) {
					const result=returnValue.returnValue;
					const buffer = toBuffer(result, 'hex');
					const token_id = buffer.readUInt32BE(1).toString(16);
					if(token_id==="0"){ return(false); }
			}
		}catch(e:any){
			console.log("Exp:"+e);
		}
		return(true);
	}
	const ubookDomain=async()=>{
		navigate(URLPREFIX+"/book-domain",{state:{domain:domain}});
	}
	const fx_search_domain=async(domain:string)=>{
		  setAvailableStatus("");
		  if(_WALLET_INFO.account===''){ jtoast.error('Please connect wallet'); return;}
		  else{
			let _domain=inputSearch;  
			if(inputSearch.toLowerCase().endsWith(".ccd"))
				setDomain(inputSearch);
			else{_domain=_domain+".ccd";setDomain(inputSearch+".ccd");}
			// find domain status
			setReqWait(true);
			let isExist=await isDomainExist(_domain);
			setAvailableStatus(isExist?"x":"y");
			console.log("Domain Existancy :"+isExist);
			setReqWait(false);
			
		  }
	}
	const fx_search=async(e:any)=>{
	  let _key:number=e.charCode | e.which;
	  if(_key===13){
		fx_search_domain(inputSearch);  
	  }
	}
  return (
  <div className='row'>
	<div className='col-md-12 '>
		<div className="d-flex flex-column">
		<div className="search-input" >
		  <div className="overlap-group  w-100">
			<div className="copy-al-2  w-100">
			  <div className="mrtoyccd-1 headingheading-small  w-100">
			  <input type='text' className='form-control input' placeholder="enter domain name"
			   onKeyPress={e=>fx_search(e)}
			   onChange={e=>{setInputSearch(e.target.value.trim().toLocaleLowerCase());}}
			   
			  />
			  </div>
			  {reqWait===true?
				<><img src={loadingGif} className='ani-md' /></>
				:
				<>
					<div className={`search-icon-1 headingheading-small pointer`} onClick={e=>fx_search_domain(inputSearch)}>
					  <img className="x-outline " src={searchIcon} alt="Search-Outline" />
					</div>
				</>
			  }
			  
			</div>
		  </div>
		  </div>  
		</div>
		<div className=''>
			<div className='text-end'>
			{availableStatus==="y"?
				<>
				<div className='mt-2 pb-2 fs-4 text-white text-shadow'>
					<span className='fw-bold'>{domain}</span> is available
				</div>
				<div className='mt-2 text-white text-shadow'><button className='btn btn-warning rounded-pill px-6 py-2 text-black  pointer'
					onClick={e=>ubookDomain()}
				> Proceed </button></div>
				</>
			:availableStatus==="x"?
				<>
				<div className='mt-2 fs-4 text-white text-shadow'>
					<span className='fw-bold'>{domain}</span> is not available <i className="bi bi-slash-circle"></i> 
				</div>
				
				</>
			:<></>	
			}
			</div>
		</div>
	</div>	
  </div>	
  );
}

export default SearchInput;
