// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input {
  align-items: flex-start;
  border-radius: 28px;
  display: flex;
  height: 144px;
  left: 0;
  width:'100%';
  padding: 8px 0;
  
 
}
.search-input .input{
	background-color:var(--gigas);
	border:0px solid var(--gigas);
	font-size:1.75rem;
	color:#fff;
}
.search-input .input:hover,.input:focus{
	background-color:var(--gigas);
	border:0px solid var(--gigas);
	box-shadow:none!important;
	outline: none!important;
}
.overlap-group {
  align-items: center;
  background-color: var(--ronchi);
  border-radius: 18px 18px 0px 0px;
  display: flex;
  height: 104px;
  padding: 0 4px;
}

.copy-al-2 {
  align-items: center;
  background-color: var(--gigas);
  border-radius: 16px;
  display: inline-flex;
  gap: 16px;
  justify-content: center;
  padding: 24px;
  position: relative;
}

.mrtoyccd-1 {
  color: var(--dark-themetypeprimary);
  font-weight: 700;
  line-height: 36px;
  position: relative;
  white-space: nowrap;

}


`, "",{"version":3,"sources":["webpack://./src/SearchInput/SearchInput.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,OAAO;EACP,YAAY;EACZ,cAAc;;;AAGhB;AACA;CACC,6BAA6B;CAC7B,6BAA6B;CAC7B,iBAAiB;CACjB,UAAU;AACX;AACA;CACC,6BAA6B;CAC7B,6BAA6B;CAC7B,yBAAyB;CACzB,uBAAuB;AACxB;AACA;EACE,mBAAmB;EACnB,+BAA+B;EAC/B,gCAAgC;EAChC,aAAa;EACb,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,oBAAoB;EACpB,SAAS;EACT,uBAAuB;EACvB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,mCAAmC;EACnC,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;;AAErB","sourcesContent":[".search-input {\n  align-items: flex-start;\n  border-radius: 28px;\n  display: flex;\n  height: 144px;\n  left: 0;\n  width:'100%';\n  padding: 8px 0;\n  \n \n}\n.search-input .input{\n\tbackground-color:var(--gigas);\n\tborder:0px solid var(--gigas);\n\tfont-size:1.75rem;\n\tcolor:#fff;\n}\n.search-input .input:hover,.input:focus{\n\tbackground-color:var(--gigas);\n\tborder:0px solid var(--gigas);\n\tbox-shadow:none!important;\n\toutline: none!important;\n}\n.overlap-group {\n  align-items: center;\n  background-color: var(--ronchi);\n  border-radius: 18px 18px 0px 0px;\n  display: flex;\n  height: 104px;\n  padding: 0 4px;\n}\n\n.copy-al-2 {\n  align-items: center;\n  background-color: var(--gigas);\n  border-radius: 16px;\n  display: inline-flex;\n  gap: 16px;\n  justify-content: center;\n  padding: 24px;\n  position: relative;\n}\n\n.mrtoyccd-1 {\n  color: var(--dark-themetypeprimary);\n  font-weight: 700;\n  line-height: 36px;\n  position: relative;\n  white-space: nowrap;\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
