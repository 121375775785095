import React, { useCallback,useEffect, useState, useRef } from 'react'
import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import {
	toBuffer,
	serializeUpdateContractParameters,
	AccountTransactionType,
	CcdAmount,
	UpdateContractPayload,
	AccountAddress,
	serializeTypeValue,
	getContractName
} from '@concordium/web-sdk';

import {
    BrowserWalletConnector,
    WalletConnectConnector,
    WalletConnection,
    WalletConnectionDelegate,
	TESTNET,
	MAINNET,
	CONCORDIUM_WALLET_CONNECT_PROJECT_ID,
	typeSchemaFromBase64
	
} from '@concordium/wallet-connectors';
import { Result, ResultAsync, err, ok } from 'neverthrow';
import { URLPREFIX,RAW_SCHEMA,RAW_SCHEMA_JSON,CCD_INFO } from './constant';
class MyDelegate implements WalletConnectionDelegate {
    accounts = new Map<WalletConnection, string | undefined>();
    chains = new Map<WalletConnection, string | undefined>();

    onAccountChanged = (connection: WalletConnection, address: string | undefined) => {
        this.accounts.set(connection, address);
    };

    onChainChanged = (connection: WalletConnection, genesisHash: string) => {
		console.log("*************Chaine:"+genesisHash)
        this.chains.set(connection, genesisHash);
    };

    onConnected = (connection: WalletConnection, address: string | undefined) => {
		console.log("*************Conbneted Account:"+address)
        this.onAccountChanged(connection, address);
    };

    onDisconnected = (connection: WalletConnection) => {
        this.accounts.delete(connection);
        this.chains.delete(connection);
    };
}

const JAppTest2=()=>{
 return(<>
			<button 
			className='btn btn-success px-4 py-2'
			onClick={async(e)=>{
				const delegate = new MyDelegate();
				console.log("0755b2b6b8b78d3674bb41a10be1ee53");
				console.log(CONCORDIUM_WALLET_CONNECT_PROJECT_ID);
				const walletConnectConnector = await WalletConnectConnector.create(
							{
								projectId:"CONCORDIUM_WALLET_CONNECT_PROJECT_ID"
							}, delegate, MAINNET);
				const walletConnectConnection:any= await walletConnectConnector.connect();
				let jsonRpcClient=walletConnectConnection.getGrpcClient();
				console.log(jsonRpcClient.provider);
				let _domain={ domain:"web23.ccd"};
				const param:any = serializeUpdateContractParameters('ccd','getDomain',_domain,toBuffer(RAW_SCHEMA, 'base64'));
				console.log(param);
				let returnValue:any=await jsonRpcClient.invokeContract({
					contract: { index: BigInt(CCD_INFO.CONTRACT_INDEX), subindex: BigInt(0) },
					method: 'ccd.getDomain',
					parameter:param
				});	
				console.log("RetrunValue");
				console.log(returnValue);
				if (returnValue && returnValue.tag === 'success' && returnValue.returnValue) {
						const result=returnValue.returnValue;
						const buffer = toBuffer(result, 'hex');
						const token_id = buffer.readUInt32BE(1).toString(16);
						if(token_id==="0"){ console.log("DOmain Avialable");return(false); }
						else console.log("DOmain  is not available");
				}
				else {
					console.log("Error:");
					console.log(returnValue);
				}
			}}
			>Connect Wallext-X</button>


<button 
			className='btn btn-primary px-4 py-2'
			onClick={async(e)=>{
				const delegate = new MyDelegate();
				console.log(CONCORDIUM_WALLET_CONNECT_PROJECT_ID);
				const walletConnectConnector = await WalletConnectConnector.create(
							{
								projectId:CONCORDIUM_WALLET_CONNECT_PROJECT_ID
							}, delegate, MAINNET);
				const walletConnectConnection:any= await walletConnectConnector.connect();
				console.log(delegate.accounts.get(walletConnectConnection));
				let accountSelected:any=delegate.accounts.get(walletConnectConnection);
				let jsonRpcClient=walletConnectConnection.getGrpcClient();
				let account: any=accountSelected;
				const id:string = Math.round(Math.random() * 100000).toString().padEnd(8, '0'); 
				let token=id;
				let url: string='{url:"rahul.ccd"}';
				let parma0:any={
							owner: {
								Account: [new AccountAddress(account).address],
							},
							tokens: [id],
							domain:"rahul.ccd",
							days:1*365,
							metadata_url: url
						}			
				const param:any = serializeTypeValue(parma0,toBuffer(RAW_SCHEMA_JSON.entrypoints.mint.parameter, 'base64'));
				let priceinDollar=35;
				let ccdRate=178;
				ResultAsync.fromPromise(
							walletConnectConnection.signAndSendTransaction(
									account,
									AccountTransactionType.Update,
									{
										amount: new CcdAmount(BigInt(priceinDollar*ccdRate*1000000)),
										address: { index: BigInt(CCD_INFO.CONTRACT_INDEX), subindex: BigInt(0) },
										receiveName: 'ccd.mint',
										maxContractExecutionEnergy: 30000n,
									},
									{parameters: parma0,
    								 schema: RAW_SCHEMA_JSON.entrypoints.mint.parameter //typeSchemaFromBase64()
									}
									
								),
								(err: any)=>{
									console.log("Errorxxxx---:");
									console.log(err);
									return (err as Error).message || (err as string);
								}
				).then((_txn:any)=>{
					console.log("_____txn");
					console.log(_txn);
				})
				/*
				{code: 5000, message: 'Failed creating transaction: crypto library internal error'}
				*/
					
				


			}}
			>Book Domain</button>
 </>)

}

export default JAppTest2;