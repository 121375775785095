import React, { useCallback,useEffect,useState,useRef,useContext } from 'react'
import { useNavigate,useLocation} from 'react-router-dom';
import './App.css';
import "./css/HomePage4.css";
import "./css/Button.css";
import loadingGif from './icons/loading.gif';
import domainImage from "./images/nft-domain.png";
import concordiumNamingService from "./img/img/concordium-naming-service@2x.png";
import shopcartOutline from "./img/img/shopcart-outline@2x.png";
import domainOutline from "./img/img/domain-outline@2x.png";
import axios from 'axios';

import {UserContext,jtoast} from "./context/context";
import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';
import {
    AccountAddress,
    AccountTransactionType,
    CcdAmount,
    ModuleReference,
    serializeUpdateContractParameters,
    toBuffer,
	TransactionStatusEnum ,
	UpdateContractPayload,
	deserializeReceiveReturnValue
	
} from '@concordium/web-sdk';
import { URLPREFIX,RAW_SCHEMA,RAW_SCHEMA_JSON,CCD_INFO,decodeString } from './constant';
import JMenuBar from "./JMenuBar";
import moment from 'moment';
import { stat } from 'fs';
import JBackBar from './JBackBar';
let _WALLET_INFO:any={
	provider:null,
	account:'',
	genesisHash:''
	
};
const JBidEntry=()=>{
	const {walletInfo,updateWalletInfo} = useContext(UserContext);
	const navigate=useNavigate();
	const {state}=useLocation();
	
	const [domain,setDomain]=useState<string>("");
	const [tokenId,setTokenId]=useState<string>("");
	const [currentAccount,setCurrentAccount]=useState<any>("");
	const [priceinCCD,setPriceinCCD]=useState<string>("0");
    const [royality,setRoyality]=useState<string>("0");
	const [reqWait,setReqWait]=useState<boolean>(false);
	const [auctionInfo,setAuctionInfo]=useState<any>(null);
	useEffect(()=>{
		_WALLET_INFO.provider=walletInfo.provider;
		_WALLET_INFO.account=walletInfo.account;
		_WALLET_INFO.genesisHash=walletInfo.genesisHash;
		setCurrentAccount(walletInfo.account);
	},[walletInfo]);
	useEffect(()=>{
		if(state && state.domain){
			setDomain(state.domain);
			setTokenId(state.token);
            setRoyality(state.royality);
            loadAuction(state.domain);
		}
		else navigate(URLPREFIX+"/");
	},[state]);
    const loadAuction=async(_domain:string)=>{
        let rec=await getAuctionInfo(_domain);
        setAuctionInfo(rec);
    }
    const getAuctionInfo=async(_domain:any)=>{
		try{
			let param={domain:_domain};
			const paramx:any = serializeUpdateContractParameters('ccd','getAuction',param,toBuffer(RAW_SCHEMA, 'base64'));
			let returnValue:any=await _WALLET_INFO.provider.getGrpcClient().invokeContract({
			contract: { index: BigInt(CCD_INFO.CONTRACT_INDEX), subindex: BigInt(0) },
			method: 'ccd.getAuction',
			parameter:paramx
		});
					if (returnValue && returnValue.tag === 'success' && returnValue.returnValue) {
						const result=returnValue.returnValue;
						const response=deserializeReceiveReturnValue(
							toBuffer(result, 'hex'),
							toBuffer(RAW_SCHEMA, 'base64'),
							'ccd',
							'getAuction',
							2
						);
						console.log("RESPONS--------");
						console.log(response);
						return({
							status:response.status,
							domain:response.domain,
							atype:response.atype,
							price:response.price,
							owner:response.owner,
							startAt:response.start_at,
							endAt:response.end_at,
							bidder:response.bidder,
							bidAmount:response.biding_amount,
							bidAt:response.bid_at,
							tokenId:response.token_id
						});
							
					} else {
						return(null);
					}
		}catch(e:any){
			console.log(e);
			return(null);
		}	
	   }
    const iambidding=async()=>{
        if(parseInt(priceinCCD)>auctionInfo.bidAmount){
            setReqWait(true);
            let txHash=await _iambidding();
            if(txHash===null){
                jtoast.error("Something went wrong [UBD-500]");
                setReqWait(false);	
            }
            else {
                setTimeout(function listen() {
                    _WALLET_INFO.provider
                        .getGrpcClient()
                        .getTransactionStatus(txHash)
                        .then(async(status:any) => {
                            if (status && status.status === TransactionStatusEnum.Finalized &&	status.outcomes	) {
                                const outcome:any = Object.values(status.outcomes)[0];
                                if (outcome.result.outcome === 'success') {
                                    let _state:any={
                                                    domain:domain,
                                                    txHash:txHash,
                                                };
									await saveBid();		
                                    setReqWait(false);
									navigate(URLPREFIX+"/bid-success",
                                                {state:_state});
                                    return;
                                    
                                } else {
                                    setReqWait(false);
                                    jtoast.error("Something went wrong [UBD-400]");
                                }
                            } else {
                                setTimeout(listen, 3000);
                            }
                        });
                }, 3000);
                    
            }    
        }
        else jtoast.error("Kindly enter price more than min price of auction");
    }
    const _iambidding=async()=>{
            
            try{
                let bidInputParam:any={
                    domain: domain,
                    amount:Number(priceinCCD)
                    };
                let txHash=await _WALLET_INFO.provider.sendTransaction(
                    currentAccount,
                    AccountTransactionType.Update,
                    {
                        amount: new CcdAmount(BigInt(bidInputParam.amount*1000000)),
                        address: { index: BigInt(CCD_INFO.CONTRACT_INDEX), subindex: BigInt(0) },
                        receiveName: 'ccd.bid',
                        maxContractExecutionEnergy: 30000n,
                    } as UpdateContractPayload,
                    bidInputParam
                    ,RAW_SCHEMA
                );
                console.log(txHash);	
                return(txHash);	
            }catch(e:any){
                console.log("e:"+e.toString());
                return(null);
            }		
        
    }
	const saveBid=async()=>{
		let _state:any={
												
			domain:domain,
			token:tokenId,
			atype:1,
			amount:parseInt(priceinCCD),
			bidder:currentAccount
		};
		const _url = process.env.REACT_APP_SDK_API_SERVER+"/api/bid/new";	
		try{
			let _response=await axios.post(_url,_state);
			console.log(_response.data);
		}catch(_e:any){
			jtoast.error("Bid has been saved, but something went wrong!");
		}
	}
  return (
	<>
	<div className='container mt-4'>
	<div className='container py-4 px-4'>
	<JBackBar backTo={URLPREFIX+"/auction-list"} />
	<div className='p-4'>
		<h2 className='rubik-bold-white-40px'>Bidding</h2>
		<div className='row mt-4'>
			<div className='col-md-4'>
				<div className='card bg-transparent'>
					<div className='card-body'>
						<div className='text-center' style={{padding:'1px'}}>
							<img src={domainImage} style={{width:'85%'}} />
							<div className='text-white text-center fs-5 ' >
								{domain}
								<div className='f75rem mt-2 text-white'>concordium</div>
								<div className='f75rem text-white mt-2'>Token:{tokenId}</div>
							</div>
						</div>
                        <div className='d-flex flex-column mt-0'>
                            <div className='d-flex py-1'><span className='flex-fill text-white'>Price</span>  <span className='text-dark text-white fw-bold'>{auctionInfo?.price} CCD</span></div>
                            <div className='d-flex py-1'><span className='flex-fill text-white'>Min Bidding Price</span>  <span className='text-dark text-white fw-bold'>{auctionInfo?.bidAmount} CCD</span></div>
                            <div className='d-flex py-1'><span className='flex-fill text-white'>Expiring In</span>  <span className='text-dark text-white fw-bold'>{moment(auctionInfo?.endAt).format('YYYY-MM-DD')}</span></div>	
                            <div className='d-flex py-1'><span className='flex-fill text-white'>Royality</span>  <span className='text-dark text-white fw-bold'>{royality}%</span></div>	
                        </div>
					</div>
				</div>
			</div>
			<div className='col-md-8'>
			<div className='fs-4 text-white'>Bid on domain</div>
				<p className=' mt-2 text-white'>
				Kindly bid the domain with greater amout of minimum bid.
				</p>
				<div className='mt-4'>
					<div className="form-floating mb-4">
						<input type="email" className="form-control" id="price" placeholder="Enter bid" onChange={(e:any)=>setPriceinCCD(e.target.value)} />
						<label >Enter bid in CCD</label>
					</div>
					<div>
						<button className='btn btn-warning rounded-pill px-6 -py-4 text-black fw-bold'
						disabled={reqWait}
                        onClick={(e)=>iambidding()}
						>
							{reqWait===true?
									<><img src={loadingGif} className='ani-xsm' /></>
								:
									<> Bid </>
							}
						</button>
					</div>
				</div>
			</div>	
		</div>	
	</div>
	</div>
	</div>
	</>
  );
}

export default JBidEntry;
