import React, { useCallback,useEffect,useState,useRef,useContext } from 'react'
import { useNavigate,useLocation} from 'react-router-dom';
import './App.css';
import "./css/HomePage4.css";
import "./css/Button.css";
import loadingGif from './icons/loading.gif';
import concordiumNamingService from "./img/img/concordium-naming-service@2x.png";
import shopcartOutline from "./img/img/shopcart-outline@2x.png";
import domainOutline from "./img/img/domain-outline@2x.png";
import profileIcon from "./img/img/thumbnail@2x.png";
import Resizer from "react-image-file-resizer";
import axios from 'axios';

import {UserContext,jtoast} from "./context/context";
import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';
import {
    AccountTransactionType,
    CcdAmount,
    ModuleReference,
    serializeUpdateContractParameters,
    toBuffer,
	TransactionStatusEnum ,
	UpdateContractPayload,
	
} from '@concordium/web-sdk';
import { URLPREFIX,RAW_SCHEMA,RAW_SCHEMA_JSON,CCD_INFO } from './constant';
import JMenuBar from "./JMenuBar";
import JBackBar from './JBackBar';
import moment from 'moment';
let _WALLET_INFO:any={
	provider:null,
	account:'',
	genesisHash:''
	
};
const JProfileEntry=()=>{
	const {walletInfo,updateWalletInfo} = useContext(UserContext);
	const navigate=useNavigate();
	const {state}=useLocation();
	
	const [name,setName]=useState<string>("");
	const [shortBio,setShortBio]=useState<string>("");
	const [twitter,setTwitter]=useState<string>("");
	const [facebook,setFacebook]=useState<string>("");
	const [mydomain,setMydomain]=useState<string>("");
	const [currentAccount,setCurrentAccount]=useState<any>("");

	const [newImage,setNewImage]=useState<any>(null);
	const [reqWait,setReqWait]=useState<boolean>(false);
	
	useEffect(()=>{
		if(walletInfo.account===""){
			navigate(URLPREFIX+"/");
			return;
		}
		myProfile(walletInfo.account);
		_WALLET_INFO.provider=walletInfo.provider;
		_WALLET_INFO.account=walletInfo.account;
		_WALLET_INFO.genesisHash=walletInfo.genesisHash;
		setCurrentAccount(walletInfo.account);
		
	},[walletInfo]);
	
	const saveProfile=async(e:any)=>{
		let _state={
			owner:currentAccount,
			name:name,
			shortBio:shortBio,
			twitter:twitter,
			facebook:facebook,	
			mydomain:mydomain
		};
		setReqWait(true);
		const _url = process.env.REACT_APP_SDK_API_SERVER+"/api/profile/4738947384834";	
		try{
			let _response=await axios.post(_url,_state);
			
			if(_response && _response.status===200 && _response.data!=undefined){
				if(_response.data.status===1)
				 {navigate(URLPREFIX+"/profile"); return;}
			}
			jtoast.error("Kindly check entered profile data!");
		}catch(_e:any){
			jtoast.error("Auction has been saved, but something went wrong!");
		}
		setReqWait(false);
	}
	/* **************FILE HNADEL******************* */
	const fileChangedHandler=(event:any)=>{
		var fileInput = false;
		if (event.target.files[0]) {
		  fileInput = true;
		}
		if (fileInput) {
		  try {
			Resizer.imageFileResizer(
			  event.target.files[0],
			  160,
			  160,
			  "JPEG",
			  100,
			  0,
			  (uri) => {
				setNewImage(uri);
				uploadIcon(uri);
			  },
			  "base64",
			  96,
			  96
			);
		  } catch (err) {
			console.log(err);
		  }
		}
	}
	const uploadIcon=async(_iconBase64:any)=>{
		let _state={owner:currentAccount,
					icon:_iconBase64};
		const _url = process.env.REACT_APP_SDK_API_SERVER+"/api/profileicon/4738947384834";	
		try{
			let _response=await axios.post(_url,_state);
		}catch(_e:any){
			console.log("EXP:"+_e.toString());
			jtoast.error("but something went wrong!");
		}
	}

	/* **************END FILE HANDLE ************** */
	const myProfile=async(_owner:any)=>{
		let _state={owner:_owner,
					};
		const _url = process.env.REACT_APP_SDK_API_SERVER+"/api/profile/4738943784834";	
		try{
			let _response=await axios.post(_url,_state);
			if(_response && _response.status===200 && _response.data.status===1){
				console.log(_response.data);
				setNewImage(_response.data?.data?.profileIcon);
				setName(_response.data?.data?.name);
				setShortBio(_response.data?.data?.shortBio);
				setFacebook(_response.data?.data?.facebook);
				setTwitter(_response.data?.data?.twitter);
				setMydomain(_response.data?.data?.mydomain);
			}
			console.log("_response.data");
			console.log(_response.data);
		}catch(_e:any){
			console.log("EXP:"+_e.toString());
			jtoast.error("but something went wrong!");
		}
	}
  return (
	<>
	<div className='container mt-4'>
	<div className='container py-4 px-4'>
	<JBackBar backTo={URLPREFIX+"/profile"} />
	<div className='p-4'>
		<h2 className='rubik-bold-white-40px'>My Profile</h2>
		<div className='row mt-4'>
			<div className='col-md-4'>
				<div className='card bg-transparent'>
					<div className='card-body'>
						<div className='fs-4 text-white'>{""}</div>
						<div className='mt-2 d-flex justify-content-center'>
							<div className='d-flex  justify-content-center overflow-hidden rounded-circle text-center' 
							style={{width:120,height:120,backgroundColor:'#481886'}}>
							<img src={newImage || profileIcon} className='align-items-center' />
							</div>
						</div>
						<div className='mt-2 text-center position-relative'>
							<input type="file" className='form-control position-absolute' onChange={fileChangedHandler} 
							style={{backgroundColor:'#481886',zIndex:999,top:0,opacity:0}} />
							<button className='btn rounded-pill px-4 -py-4 text-white fw-bold'
							style={{backgroundColor:'#481886'}}
							onClick={e=>console.log}
							disabled={reqWait}
							>
								{reqWait===true?
										<><img src={loadingGif} className='ani-xsm' /></>
									:
										<> <i className="bi bi-arrow-up"></i> </>
								}
							</button>
					</div>
					</div>
				</div>
			</div>
			<div className='col-md-8'>
					<div className="mb-4">
						<div className="text-white fs-4">
								Personal Information
						</div>
					</div>
				<div className='mt-4'>
					<div className="form-floating mb-4">
						<input type="text" className="form-control" id="name" placeholder="Enter name" value={name} onChange={(e:any)=>setName(e.target.value)} />
						<label >Enter name</label>
					</div>
					<div className="form-floating mb-4">
						<textarea style={{height:'120px!important'}} className="form-control" id="shortBio" placeholder="Enter short bio" value={shortBio} onChange={(e:any)=>setShortBio(e.target.value)}>{shortBio}</textarea>
						<label >Start short bio</label>
					</div>
					<div className="mb-4 pt-4 ">
						<div className="text-white fs-4">
								Social Links
						</div>
					</div>
					<div className="form-floating mb-4">
						<input type="text" className="form-control" id="twitter" placeholder="Enter twitter url" value={twitter} onChange={(e:any)=>setTwitter(e.target.value)} />
						<label >Enter Twitter</label>
					</div>
					<div className="form-floating mb-4">
						<input type="text" className="form-control" id="facebook" placeholder="Enter facebook url" value={facebook} onChange={(e:any)=>setFacebook(e.target.value)} />
						<label >Enter Facebook</label>
					</div>
					<div className="form-floating mb-4">
						<input type="text" className="form-control" id="domain" placeholder="Enter domain url" value={mydomain} onChange={(e:any)=>setMydomain(e.target.value)} />
						<label >Enter Domain URL</label>
					</div>
					<div>
						<button className='btn btn-warning rounded-pill px-4 -py-4 text-black fw-bold'
						onClick={e=>saveProfile(e)}
						disabled={reqWait}
						>
							{reqWait===true?
									<><img src={loadingGif} className='ani-xsm' /></>
								:
									<> Submit </>
							}
						</button>
					</div>
				</div>
			</div>	
		</div>	
	</div>
	</div>
	</div>
	</>
  );
}

export default JProfileEntry;
