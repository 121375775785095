// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-icon-1 {
  align-items: center;
  background-color: #e86e3a;
  border-radius: 24px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px;
  position: relative;
}

.x-outline {
  height: 32px;
  position: relative;
  width: 32px;
}

.search-icon-1.search-icon {
  background-color: var(--white);
}

`, "",{"version":3,"sources":["webpack://./src/SearchInput/SearchIcon.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,oBAAoB;EACpB,cAAc;EACd,SAAS;EACT,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".search-icon-1 {\n  align-items: center;\n  background-color: #e86e3a;\n  border-radius: 24px;\n  display: inline-flex;\n  flex: 0 0 auto;\n  gap: 10px;\n  padding: 8px;\n  position: relative;\n}\n\n.x-outline {\n  height: 32px;\n  position: relative;\n  width: 32px;\n}\n\n.search-icon-1.search-icon {\n  background-color: var(--white);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
