import React, { useCallback,useEffect,useState,useRef,useContext } from 'react'
import { useNavigate,useLocation} from 'react-router-dom';
import './App.css';
import "./css/HomePage4.css";
import "./css/Button.css";
import loadingGif from './icons/loading.gif';
import concordiumNamingService from "./img/img/concordium-naming-service@2x.png";
import shopcartOutline from "./img/img/shopcart-outline@2x.png";
import domainOutline from "./img/img/domain-outline@2x.png";
import axios from 'axios';
import moment from 'moment';

import {UserContext,jtoast} from "./context/context";
import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';
import {
	AccountAddress,
    AccountTransactionType,
    CcdAmount,
    ModuleReference,
    serializeUpdateContractParameters,
	deserializeReceiveReturnValue,
    toBuffer,
	TransactionStatusEnum ,
	UpdateContractPayload,
	
} from '@concordium/web-sdk';
import { URLPREFIX,RAW_SCHEMA,RAW_SCHEMA_JSON,CCD_INFO,decodeString } from './constant';
import JMenuBar from "./JMenuBar";
let _WALLET_INFO:any={
	provider:null,
	account:'',
	genesisHash:''
	
};
const JMyDomainListComponent=()=>{
	const {walletInfo,updateWalletInfo} = useContext(UserContext);
	const navigate=useNavigate();
	const {state}=useLocation();
	
	const [domain,setDomain]=useState<string>("");
	const [currentAccount,setCurrentAccount]=useState<any>("");
	const [reqWait,setReqWait]=useState<boolean>(false);
	const [rows,setRows]=useState<any>([]);
	const [domainList,setDomainList]=useState<any>([]);
	useEffect(()=>{
		if(walletInfo.account===""){
			navigate(URLPREFIX+"/");
			return;
		}
		_WALLET_INFO.provider=walletInfo.provider;
		_WALLET_INFO.account=walletInfo.account;
		_WALLET_INFO.genesisHash=walletInfo.genesisHash;
		console.log("OKAY ----");
		setCurrentAccount(walletInfo.account);
		getCCDDomains(walletInfo.account);
	},[walletInfo]);
	useEffect(()=>{
		myDomainList(rows);
	},[rows])

		const getCCDDomains=async(_owner:any)=>{
		try{
			let response=await axios.post(process.env.REACT_APP_SDK_API_SERVER+"/api/ccd-domains",{owner:_owner});
			console.log(response.data);
			if(response.status===200 && response.data!=undefined){
				let data:any=response.data;
					if(data.status===1){
						console.log(data.data);
						setRows(data.data);
					}
			}
		}catch(e:any){
		}
	    }

		const isTokenOwner=async(_account:any,_tokenId:any)=>{
			try{
				let param=[
							{
								address: {
									Account: [_account],
								},
								token_id: _tokenId,
							},
						];
				const paramx:any = serializeUpdateContractParameters('ccd','balanceOf',param,toBuffer(RAW_SCHEMA, 'base64'));
				let returnValue:any=await _WALLET_INFO.provider.getGrpcClient().invokeContract({
				contract: { index: BigInt(CCD_INFO.CONTRACT_INDEX), subindex: BigInt(0) },
				method: 'ccd.balanceOf',
				parameter:paramx
			});
						if (returnValue && returnValue.tag === 'success' && returnValue.returnValue) {
								const result=returnValue.returnValue;
								console.log(result);
								const response=deserializeReceiveReturnValue(
									toBuffer(result, 'hex'),
									toBuffer(RAW_SCHEMA, 'base64'),
									'ccd',
									'balanceOf',
									2
								);
								if(response && response.length===1) return(parseInt(response[0]));
								return(0);
						} else {
							return(-1);
						}
			}catch(e:any){
				console.log(e);
				return(-1);
			}	
		}
		const myDomainList=async(_rows:any)=>{
			let i=0;
			let _domainList:any=[];
			for(i=0;i<_rows.length;i++){
				let _tokenId=_rows[i]?.token;
				let _domain=_rows[i]?.domain;
				if(_tokenId!=undefined && _domain!=undefined){	
					let balance:any=await isTokenOwner(currentAccount,_tokenId);
					if(balance===0){
					} else{
						try{
							let responseDB=await axios.post(process.env.REACT_APP_SDK_API_SERVER+"/api/bid/get",
												{owner:currentAccount,domain:_domain}
										);
							if(responseDB && responseDB.status===200 && responseDB.data){
								let db:any=responseDB.data;
								if(db.status===0)
									_domainList.push(_rows[i]);
							}
						}catch(e0:any){}
						
					}
				}
			}
			setDomainList(_domainList);
		}
  return (
	<>
	<div className='container mt-4'>
	<div className='container py-4 px-4'>
	<div className='p-4'>
		<h2 className='rubik-bold-white-40px'>Domain List</h2>
		<div className='row mt-4 py-4'>
			<table className='xtable mouse'>
				<thead>
				<tr>
				<td><div className='fs-6 text-silver text-shadow px-4 py-1'>Domain</div></td>
				<td><div className='fs-6 text-silver text-shadow px-4 py-1'>Booked On</div></td>
				<td><div className='fs-6 text-silver text-shadow px-4 py-1'>Expiring On</div></td>
				<td><div className='fs-6 text-silver text-shadow px-4 py-1'>Price<br/><span className='f75rem'>(CCD)</span></div></td>
				<td><div className='fs-6 text-silver text-shadow px-4 py-1'>Token</div></td>
				<td><div className='fs-6 text-silver text-shadow px-4 py-1'></div></td>
				</tr>
				</thead>	
				<tbody>
		{domainList && domainList.map((rec:any,index:Number)=>{
			return(
				<tr>
				<td><span className='fs-5 text-white text-shadow px-4 py-1'>{rec.domain}</span></td>
				<td><span className='fs-5 text-white text-shadow px-4 py-1 nowrap'>{moment(rec.createdAt).format('YYYY-MM-DD')}</span></td>
				<td><span className='fs-5 text-white text-shadow px-4 py-1 nowrap'>{moment(rec.createdAt).add(rec.life,'d').format('YYYY-MM-DD')}</span></td>
				<td><span className='fs-2 text-white text-shadow px-4 py-1'>{rec.ccd} <span className='f75rem'>(CCD)</span></span></td>
				<td><span className='fs-5 text-white text-shadow px-4 py-1'>{rec.token}</span></td>
				<td align='center'>
					<button className='btn btn-sm btn-warning rounded-pill px-6 py-2 text-black fw-bold pointer'
					id={'_'+index+'_btn'}
					onClick={e=>{
						let domain=rec.domain;
						let token=rec.token;
						navigate(URLPREFIX+"/auction-entry",{state:{domain:domain,token:token}});
					}}
					>
					Manage
				</button>
				</td>
				</tr>
			);}
		)}	
			</tbody>	
		</table>
		</div>	
	</div>
	</div>
	</div>
	</>
  );
}

export default JMyDomainListComponent;
