import React,{useState,useEffect} from 'react';
import { BrowserRouter,Routes, Route, Navigate,useLocation} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import './index.css';
import JMenuBar from './JMenuBar';
import JFooter from	"./JFooter";
import JApp from './JApp';
import JBookDomain from './JBookDomain';
import JBookDomainList from './JBookDomainList';
import JBookDomainSuccess from './JBookDomainSuccess';
import JAuctionDomianEntry from './JAuctionDomianEntry';
import JAuctionDomainSuccess from './JAuctionDomainSuccess';
import JAuctionList from './JAuctionList';
import JProfileEntry from './JProfileEntry';
import JProfile from './JProfile';
import App from './App';
import BookDomain from './BookDomain';
import BookDomainSuccess from './BookDomainSuccess';

import AppNFTTest from './AppNFTTest';
import AppNFTTest2 from './AppNFTTest2';
import {UserContext,WalletInfo_Type,walletInfoValue} from "./context/context";
import {URLPREFIX} from "./constant" ;

import JBidEntry from './JBidEntry';
const Root=()=>{
	const [walletInfo,setWalletInfo]=useState<WalletInfo_Type>(walletInfoValue);
	const updateWalletInfo=(update: WalletInfo_Type)=>{
		setWalletInfo(update);
		console.log("Context Updated");
	}
return(
	<>
	<BrowserRouter>
	<UserContext.Provider value={{walletInfo,updateWalletInfo}}>
		<JMenuBar/>
		<div style={{minHeight:'480px'}}>
		<Routes>
		{/*<Route path={URLPREFIX+"/test"}  element={<App />}  />
			<Route path={URLPREFIX+"/book-domain-0"}  element={<BookDomain/>}  />
			<Route path={URLPREFIX+"/book-domain-success-0"}  element={<BookDomainSuccess/>}  />
		*/}	
			<Route path={URLPREFIX+"/kdfu59734udsfhksru43-45947hdfkjh45y489"}  element={<AppNFTTest/>}  />
			<Route path={URLPREFIX+"/jtest2"}  element={<AppNFTTest2/>}  />
			<Route path={URLPREFIX+"/"}  element={<JApp/>}  />
			<Route path={URLPREFIX+"/book-domain"}  element={<JBookDomain/>}  />
			<Route path={URLPREFIX+"/book-domain-success"}  element={<JBookDomainSuccess/>}  />
			<Route path={URLPREFIX+"/book-domain-list"}  element={<JBookDomainList/>}  />
			<Route path={URLPREFIX+"/auction-entry"}  element={<JAuctionDomianEntry/>}  />
			<Route path={URLPREFIX+"/auction-list"}  element={<JAuctionList/>}  />
			<Route path={URLPREFIX+"/bid"}  element={<JBidEntry/>}  />
			<Route path={URLPREFIX+"/bid-success"}  element={<JAuctionDomainSuccess/>}  />
			<Route path={URLPREFIX+"/profile-entry"}  element={<JProfileEntry/>}  />
			<Route path={URLPREFIX+"/profile"}  element={<JProfile/>}  />
		</Routes>
		</div>
		<JFooter/>
	<ToastContainer/>
	</UserContext.Provider>
	</BrowserRouter>
	
	</>
)
}
export default Root;

