import {createContext} from "react";
import {toast } from 'react-toastify';
export interface WalletInfo_Type {
    provider:any;
	account:any;
	genesisHash:any;
	walletName:any;
};
export const walletInfoValue:WalletInfo_Type={
	provider:null,
	account:'',
	genesisHash:'',
	walletName:''
};
export interface WalletInfoContext_Type{
  walletInfo: WalletInfo_Type;
  updateWalletInfo: (update: WalletInfo_Type) => void;
};
export const walletInfoContext:WalletInfoContext_Type={
	walletInfo:walletInfoValue,
	updateWalletInfo:() => void 0
	
};
export const jtoast:any={
						error:(_message:any)=>toast.error(_message, {position: toast.POSITION.TOP_RIGHT,autoClose: 2000}),
						success:(_message:any)=>toast.success(_message, {position: toast.POSITION.TOP_RIGHT,autoClose: 800,className: 'toast-message-x'}),
						
};
const UserWalletContext = createContext(walletInfoContext);
export const UserContext=UserWalletContext;