// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bg-black-metal {
  background: rgba(29,28,30,1);
  
}
.bg-black-65 {
  background: rgba(0,0,0,0.65);
  
}
.img-sm { width:36px; }
.nowrap{ white-space: pre;}
.f75rem{ font-size:0.75rem!important;}
.f85rem{ font-size:0.85rem!important;}
.f1rem{ font-size:1rem!important;}
.pointer {cursor: pointer;}
.mouse {cursor: default;}
.select-primary {
  background: #5f3595!important;
  color:#fff;
}
.text-justify{
  text-align: justify;
  text-justify: inter-word;
}
.toast-message-x {
  background: #5f3595!important;
  color: #fff!important;
  font-size: 14px;
  padding:0px!important;
  margin: 0px;
}
.text-silver{ color:#babecc;}
table.xtable thead tr td {
  padding:8px;
}
table.xtable tbody tr td {
  padding:8px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,4BAA4B;;AAE9B;AACA;EACE,4BAA4B;;AAE9B;AACA,UAAU,UAAU,EAAE;AACtB,SAAS,gBAAgB,CAAC;AAC1B,SAAS,2BAA2B,CAAC;AACrC,SAAS,2BAA2B,CAAC;AACrC,QAAQ,wBAAwB,CAAC;AACjC,UAAU,eAAe,CAAC;AAC1B,QAAQ,eAAe,CAAC;AACxB;EACE,6BAA6B;EAC7B,UAAU;AACZ;AACA;EACE,mBAAmB;EACnB,wBAAwB;AAC1B;AACA;EACE,6BAA6B;EAC7B,qBAAqB;EACrB,eAAe;EACf,qBAAqB;EACrB,WAAW;AACb;AACA,cAAc,aAAa,CAAC;AAC5B;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n.ellipsis {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.bg-black-metal {\n  background: rgba(29,28,30,1);\n  \n}\n.bg-black-65 {\n  background: rgba(0,0,0,0.65);\n  \n}\n.img-sm { width:36px; }\n.nowrap{ white-space: pre;}\n.f75rem{ font-size:0.75rem!important;}\n.f85rem{ font-size:0.85rem!important;}\n.f1rem{ font-size:1rem!important;}\n.pointer {cursor: pointer;}\n.mouse {cursor: default;}\n.select-primary {\n  background: #5f3595!important;\n  color:#fff;\n}\n.text-justify{\n  text-align: justify;\n  text-justify: inter-word;\n}\n.toast-message-x {\n  background: #5f3595!important;\n  color: #fff!important;\n  font-size: 14px;\n  padding:0px!important;\n  margin: 0px;\n}\n.text-silver{ color:#babecc;}\ntable.xtable thead tr td {\n  padding:8px;\n}\ntable.xtable tbody tr td {\n  padding:8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
