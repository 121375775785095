// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-1 {
  align-items: flex-start;
  border-radius: 28px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  height: 64px;
  padding: 4px;
  position: relative;
}

.bg-1 {
  background-color: var(--ronchi);
  border-radius: 28px;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.copy-al-1 {
  align-items: center;
  align-self: stretch;
  background-color: var(--gigas);
  border-radius: 32px;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 12px 24px;
  position: relative;
  width: 100%;
}

.connect-wallet {
  color: var(--dark-themetypeprimary);
  font-weight: 700;
  line-height: 24px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

`, "",{"version":3,"sources":["webpack://./src/css/Button.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;EACpB,cAAc;EACd,sBAAsB;EACtB,SAAS;EACT,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,YAAY;EACZ,OAAO;EACP,kBAAkB;EAClB,MAAM;EACN,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,cAAc;EACd,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,mCAAmC;EACnC,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;AACpB","sourcesContent":[".button-1 {\n  align-items: flex-start;\n  border-radius: 28px;\n  display: inline-flex;\n  flex: 0 0 auto;\n  flex-direction: column;\n  gap: 10px;\n  height: 64px;\n  padding: 4px;\n  position: relative;\n}\n\n.bg-1 {\n  background-color: var(--ronchi);\n  border-radius: 28px;\n  height: 64px;\n  left: 0;\n  position: absolute;\n  top: 0;\n  width: 100%;\n}\n\n.copy-al-1 {\n  align-items: center;\n  align-self: stretch;\n  background-color: var(--gigas);\n  border-radius: 32px;\n  display: flex;\n  flex: 0 0 auto;\n  justify-content: center;\n  padding: 12px 24px;\n  position: relative;\n  width: 100%;\n}\n\n.connect-wallet {\n  color: var(--dark-themetypeprimary);\n  font-weight: 700;\n  line-height: 24px;\n  position: relative;\n  text-align: center;\n  white-space: nowrap;\n  width: fit-content;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
