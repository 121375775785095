import React, { useCallback,useEffect,useState,useRef,useContext } from 'react'
import { useNavigate,useLocation} from 'react-router-dom';
import { URLPREFIX } from './constant';
const JBackBar=(props:any)=>{
    
    const navigate=useNavigate();
    return(
        <>
        <div className='d-flex px-4'>
            <div className='text-white pointer'
            onClick={e=>navigate(props.backTo)}
            >
            <span className='fs-6'>&#8592;</span> <span className='f85rem'>Back</span>
            </div>
        </div>
        </>
    );
}

export default JBackBar;