import React, { useCallback,useEffect,useState,useRef,useContext } from 'react'
import { useNavigate,useLocation, Link} from 'react-router-dom';
import { URLPREFIX } from './constant';
import concordiumIcon from "./img/img/group@2x.png";
import web23Icon from './img/img/web23-badge@2x.png';
import twitterIcon from './img/img/twitter-bird@2x.png';
const JBackBar=(props:any)=>{
    
    const navigate=useNavigate();
    return(
        <div className='container mt-4 px-4' style={{paddingTop:'32px',paddingBottom:'32px'}}>
            <div className='d-flex'>
                <div className='d-flex flex-fill mt-4 px-4 gap-4 align-items-center ' >
                    <div >
                        <Link to="https://www.concordium.com" target='_bank'>
                            <img src={concordiumIcon} style={{maxHeight:'30px'}} />
                        </Link>
                    </div>
                    <div>
                        <Link to="https://web23.io" target='_bank'>
                            <img src={web23Icon} style={{maxHeight:'36px'}} />
                        </Link>
                    </div>
                    <div className='d-flex flex-column'>
                        <span className='text-white fs-6'>&#169; 2023 All Rights Reserved</span>
                        {/*<span className=''>
                        <Link to="#" className='text-silver f85rem mt-1'>
                            Privacy Policy
                        </Link>    
                        </span>*/}
                    </div>
                </div>
                <div className='d-flex mt-4 px-4 gap-4 align-items-end ' >
                    <div >
                        <Link to="https://twitter.com/web23io" target='_bank'>
                            <img src={twitterIcon} style={{maxHeight:'24px'}} />
                        </Link>
                    </div>
                </div>
            </div>    
        </div>
    );
}

export default JBackBar;