import React, { useCallback,useEffect,useState,useRef,useContext } from 'react'
import { useNavigate,useLocation} from 'react-router-dom';
import './App.css';
import "./css/HomePage4.css";
import "./css/Button.css";
import loadingGif from './icons/loading.gif';
import domainImage from "./images/nft-domain.png";
import concordiumNamingService from "./img/img/concordium-naming-service@2x.png";
import shopcartOutline from "./img/img/shopcart-outline@2x.png";
import domainOutline from "./img/img/domain-outline@2x.png";
import axios from 'axios';

import {UserContext,jtoast} from "./context/context";
import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';
import {
    AccountTransactionType,
    CcdAmount,
    ModuleReference,
    serializeUpdateContractParameters,
    toBuffer,
	TransactionStatusEnum ,
	UpdateContractPayload,
	
} from '@concordium/web-sdk';
import { CCD_NETWORK,URLPREFIX,RAW_SCHEMA,RAW_SCHEMA_JSON,CCD_INFO } from './constant';
import JBackBar from './JBackBar';

let _WALLET_INFO:any={
	provider:null,
	account:'',
	genesisHash:''
	
};
const JBookDomainSuccess=()=>{
	const {walletInfo,updateWalletInfo} = useContext(UserContext);
	const navigate=useNavigate();
	const {state}=useLocation();
	const [domain,setDomain]=useState<string>("");
	const [txHash,setTxHash]=useState<string>("");
	
	const [currentAccount,setCurrentAccount]=useState<any>("");
	useEffect(()=>{
		_WALLET_INFO.provider=walletInfo.provider;
		_WALLET_INFO.account=walletInfo.account;
		_WALLET_INFO.genesisHash=walletInfo.genesisHash;
		setCurrentAccount(walletInfo.account);
	},[walletInfo]);
	useEffect(()=>{
		//setDomain("apple.ccd");setTxHash("fhsdfkhdskjfsh3475984ufsdfhdkjh498357489weuhfdkfh49857");
		if(state && state.domain){
			setDomain(state.domain);setTxHash(state.txHash);
		}
		//else navigate(URLPREFIX+"/");
		//setDomain("Apple-Green.ccd");
		//setTxHash("726c1b68040964528476596137f4f6de7ae19deb251b7b5b35d6e3b43486c667");
	});
	
  return (
  <div className='container mt-4'>
	<div className='container py-4 px-4'>
	<JBackBar backTo={URLPREFIX+"/"} />
	<div className='p-4'>
		<h2 className='rubik-bold-white-40px mb-4'>Booking Transaction</h2>
		<div className='d-flex mt-4 p-4'>
			<div className='rounded-4' style={{padding:"3rem",width:"100%",backgroundColor:'#00000033'}}>
				<div>
					<div>
						<div className='position-relative text-center' style={{padding:'1px'}}>
							<img src={domainImage}  className='rounded-circle border-2 shadow-sm' style={{width:'100px'}} />
						</div>
					</div>
					<div>
						<div className='text-end mb-4'>
							<div className='fs-7 text-white text-shadow'></div>
						</div>
						<div className='text-start mb-4'>
							<span className='fs-3 text-white text-shadow px-4 py-1 rounded-2' style={{backgroundColor:'#00000089'}}>{domain}</span>
						</div>
						<div className='d-flex flex-column mb-4'>
							<div className="fs-4 mt-4 mb-4 text-silver shadow-lg flex-grow-1">
							Successfully booked 
							</div>
							<div className="fs-6 text-white shadow-lg flex-grow-1">
							{txHash && txHash.length>=10 && 
								<>
									{/*
									<a className='ja text-white' target="_blank" href={CCD_NETWORK=='mainnet'?`${'https://ccdscan.io/transactions?dcount=1&dentity=transaction&dhash='}`:`${'https://testnet.ccdscan.io/transactions?dcount=1&dentity=transaction&dhash='}``${txHash}`>
									 */}
									<a className='ja text-white' target="_blank" href={('https://ccdscan.io/transactions?dcount=1&dentity=transaction&dhash='+txHash)}>
									<span className=''>Txn.Hash: </span> <span className='text-silver'>{txHash}</span> <i className="bi bi-box-arrow-up-right f75rem text-silver"></i>
									
									</a>
								</>}
							</div>
							<div className="fs-6 shadow-lg flex-grow-1 mt-4" style={{color:'#fe8978'}}>
								* To view registered domains on Concordium wallet, got to &lt;Manage&gt;- menu and enter '<b>Contract Index</b>': <b className='fs-5'>9377</b> 
							</div>
						</div>
						
						<div className=''>
							<div className='text-end text-white fs-6'>
							<div className='mt-2 text-white text-shadow'>
								<button className='btn btn-success rounded-pill px-4'
									onClick={e=>navigate(URLPREFIX+"/")}
								>Book more</button></div>
							</div>
						</div>
					</div>
				</div>		
			</div>
		</div>	
	</div>
	</div>
	</div>
  );
}

export default JBookDomainSuccess;
