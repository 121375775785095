import React, { useCallback,useEffect,useState,useRef,useContext } from 'react'
import { useNavigate,useLocation} from 'react-router-dom';
import './App.css';
import "./css/HomePage4.css";
import "./css/Button.css";
import loadingGif from './icons/loading.gif';
import concordiumNamingService from "./img/img/concordium-naming-service@2x.png";
import shopcartOutline from "./img/img/shopcart-outline@2x.png";
import domainOutline from "./img/img/domain-outline@2x.png";
import profileIcon from "./img/img/thumbnail@2x.png";
import Resizer from "react-image-file-resizer";
import axios from 'axios';

import {UserContext,jtoast} from "./context/context";
import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';
import {
    AccountTransactionType,
    CcdAmount,
    ModuleReference,
    serializeUpdateContractParameters,
    toBuffer,
	TransactionStatusEnum ,
	UpdateContractPayload,
	
} from '@concordium/web-sdk';
import { URLPREFIX,RAW_SCHEMA,RAW_SCHEMA_JSON,CCD_INFO,CCD_NETWORK } from './constant';
import JAuctionDomainListComponent from "./JAuctionDomainListComponent";
import JMyDomainListComponent from "./JMyDomainListComponent";
import JBackBar from './JBackBar';
import moment from 'moment';
let _WALLET_INFO:any={
	provider:null,
	account:'',
	genesisHash:''
	
};
const JProfileEntry=()=>{
	const {walletInfo,updateWalletInfo} = useContext(UserContext);
	const navigate=useNavigate();
	const {state}=useLocation();
	
	const [name,setName]=useState<string>("");
	const [shortBio,setShortBio]=useState<string>("");
	const [twitter,setTwitter]=useState<string>("");
	const [facebook,setFacebook]=useState<string>("");
	const [mydomain,setMydomain]=useState<string>("");
	const [currentAccount,setCurrentAccount]=useState<any>("");

	const [newImage,setNewImage]=useState<any>(null);
	const [reqWait,setReqWait]=useState<boolean>(false);
	
	useEffect(()=>{
		if(walletInfo.account===""){
			navigate(URLPREFIX+"/");
			return;
		}
		myProfile(walletInfo.account);
		_WALLET_INFO.provider=walletInfo.provider;
		_WALLET_INFO.account=walletInfo.account;
		_WALLET_INFO.genesisHash=walletInfo.genesisHash;
		setCurrentAccount(walletInfo.account);
		
	},[walletInfo]);
	/* **************FILE HNADEL******************* */
	const fileChangedHandler=(event:any)=>{
		var fileInput = false;
		if (event.target.files[0]) {
		  fileInput = true;
		}
		if (fileInput) {
		  try {
			Resizer.imageFileResizer(
			  event.target.files[0],
			  160,
			  160,
			  "JPEG",
			  100,
			  0,
			  (uri) => {
				setNewImage(uri);
				uploadIcon(uri);
			  },
			  "base64",
			  96,
			  96
			);
		  } catch (err) {
			console.log(err);
		  }
		}
	}
	const uploadIcon=async(_iconBase64:any)=>{
		let _state={owner:currentAccount,
					icon:_iconBase64};
		const _url = process.env.REACT_APP_SDK_API_SERVER+"/api/profileicon/4738947384834";	
		try{
			let _response=await axios.post(_url,_state);
		}catch(_e:any){
			console.log("EXP:"+_e.toString());
			jtoast.error("but something went wrong!");
		}
	}

	/* **************END FILE HANDLE ************** */
	const myProfile=async(_owner:any)=>{
		let _state={owner:_owner,
					};
		const _url = process.env.REACT_APP_SDK_API_SERVER+"/api/profile/4738943784834";	
		try{
			let _response=await axios.post(_url,_state);
			if(_response && _response.status===200 && _response.data.status===1){
				console.log(_response.data);
				setNewImage(_response.data?.data?.profileIcon);
				setName(_response.data?.data?.name);
				setShortBio(_response.data?.data?.shortBio);
				setFacebook(_response.data?.data?.facebook);
				setTwitter(_response.data?.data?.twitter);
				setMydomain(_response.data?.data?.mydomain);
			}
			console.log("_response.data");
			console.log(_response.data);
		}catch(_e:any){
			console.log("EXP:"+_e.toString());
			jtoast.error("but something went wrong!");
		}
	}
  return (
	<>
	<div className='container mt-4'>
	<div className='container py-4 px-4'>
	<JBackBar backTo={URLPREFIX+"/"} />
	<div className='p-4'>
		<div className='row'>
		<div className='col-md-12 mx-auto'>
			
				<div className='mt-2 d-flex justify-content-center gap-4'>
					<div className='d-flex justify-content-center overflow-hidden rounded-circle text-center' 
					style={{width:120,height:120,backgroundColor:'#481886'}}>
					<img src={newImage || profileIcon} className='align-items-center' />
					</div>
					<div className='d-flex flex-column' style={{maxWidth:520}}>
						<div className='fs-2 fw-bold text-white'>{name}</div>
						<div className='fs-6 mt-2 text-silver pointer'>{currentAccount} 
						{/*<a className='f75rem ja text-silver px-2' target="_blank" referrerPolicy='no-referrer' href={CCD_NETWORK==="testnet"?"https://testnet.ccdscan.io/accounts?dcount=1&dentity=account&daddress="+currentAccount:"https://ccdscan.io/accounts?dcount=1&dentity=account&daddress="+currentAccount}>
							<i className="bi bi-box-arrow-up-right"></i>
  						</a>*/}
						{<a className='f75rem ja text-silver px-2' target="_blank" referrerPolicy='no-referrer' href={"https://ccdscan.io/accounts?dcount=1&dentity=account&daddress="+currentAccount}>
							<i className="bi bi-box-arrow-up-right"></i>
  						</a>}
						</div>
						<div className='fs-6 text-white mt-2 text-justify' style={{lineHeight:'1.5rem'}}>{shortBio}</div>
						<div className='mt-3'>
							<button className='btn btn-sm btn-outline-warning rounded-pill px-4'
							onClick={e=>navigate(URLPREFIX+"/profile-entry")}
							>
								Edit Profile
							</button>
						</div>
					</div>
				</div>
					
			
		</div>
		</div>
		{/*
		<JAuctionDomainListComponent />	
					*/}
		<JMyDomainListComponent />


	</div>
	</div>
	</div>

	
	</>
  );
}

export default JProfileEntry;
