import React, { useCallback,useEffect,useState,useRef,useContext } from 'react'
import { useNavigate,useLocation} from 'react-router-dom';
import './App.css';
import "./css/HomePage4.css";
import "./css/Button.css";
import loadingGif from './icons/loading.gif';
import domainImage from "./images/nft-domain.png";
import concordiumNamingService from "./img/img/concordium-naming-service@2x.png";
import shopcartOutline from "./img/img/shopcart-outline@2x.png";
import domainOutline from "./img/img/domain-outline@2x.png";
import axios from 'axios';

import {UserContext,jtoast} from "./context/context";
import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';
import {
    AccountTransactionType,
    CcdAmount,
    ModuleReference,
    serializeUpdateContractParameters,
    toBuffer,
	TransactionStatusEnum ,
	UpdateContractPayload,
	
} from '@concordium/web-sdk';
import { URLPREFIX,RAW_SCHEMA,RAW_SCHEMA_JSON,CCD_INFO } from './constant';
import JMenuBar from "./JMenuBar";
import moment from 'moment';
import Calendar from 'react-calendar';

let _WALLET_INFO:any={
	provider:null,
	account:'',
	genesisHash:''
	
};
const JBookDomain=()=>{
	const {walletInfo,updateWalletInfo} = useContext(UserContext);
	const navigate=useNavigate();
	const {state}=useLocation();
	const [calanderEndDate,setCalanderEndDate]=useState<any>(moment().add(10,'d'));
	const [ showCalander,setShowCalander]=useState(false);
	const [domain,setDomain]=useState<string>("");
	const [tokenId,setTokenId]=useState<string>("");
	const [currentAccount,setCurrentAccount]=useState<any>("");
	const [priceinCCD,setPriceinCCD]=useState<string>("0");
	const [startDate,setStartDate]=useState<string>(moment().format('YYYY-MM-DD'));
	const [endDate,setEndDate]=useState<string>(moment().add(10,'d').format('YYYY-MM-DD'));
	const [royality,setRoyality]=useState<number>(0);
	const [reqWait,setReqWait]=useState<boolean>(false);
	
	useEffect(()=>{
		_WALLET_INFO.provider=walletInfo.provider;
		_WALLET_INFO.account=walletInfo.account;
		_WALLET_INFO.genesisHash=walletInfo.genesisHash;
		setCurrentAccount(walletInfo.account);
	},[walletInfo]);
	useEffect(()=>{
		if(state && state.domain && state.token){
			setDomain(state.domain);
			setTokenId(state.token);
		}
		else navigate(URLPREFIX+"/");
	});
	
	const _putIntoAuction=async(domain:string,tokenId:string)=>{
		try{
			let actionInputParam:any={
				domain: domain,
				atype:1,   // 0->fixed,1->action
				price:parseInt(priceinCCD),
				start_at:Date.parse(startDate),
				end_at:Date.parse(moment(calanderEndDate).format("YYYY-MM-DD")),
				token_id:tokenId
				};
				
			//console.log(actionInputParam);	
			//return(null);
			//const param:any = serializeUpdateContractParameters('ccd','prepareAuction',actionInputParam,toBuffer(RAW_SCHEMA, 'base64'));
			//console.log(param);
			//return(null);
			let txHash=await _WALLET_INFO.provider.sendTransaction(
				currentAccount,
				AccountTransactionType.Update,
				{
					amount: new CcdAmount(0n),
					address: { index: BigInt(CCD_INFO.CONTRACT_INDEX), subindex: BigInt(0) },
					receiveName: 'ccd.prepareAuction',
					maxContractExecutionEnergy: 30000n,
				} as UpdateContractPayload ,
				actionInputParam
				,RAW_SCHEMA
			);
			console.log(txHash);	
			return(txHash);	
		}catch(e:any){
			console.log("e:"+e.toString());
			return(null);
		}		
	}
	const putIntoAuction=async(e:any)=>{
		setReqWait(true);
		let txHash:any=await _putIntoAuction(domain,tokenId);
		if(txHash===null){
			jtoast.error("Something went wrong [UBD-500]");
			setReqWait(false);	
		}
		else {
			setTimeout(function listen() {
				_WALLET_INFO.provider
					.getGrpcClient()
					.getTransactionStatus(txHash)
					.then(async(status:any) => {
						if (status && status.status === TransactionStatusEnum.Finalized &&	status.outcomes	) {
							const outcome:any = Object.values(status.outcomes)[0];
							if (outcome.result.outcome === 'success') {
								jtoast.success("Successfully domain has been sent into marketplace");
								await saveAuction();
								setReqWait(false);
								navigate(URLPREFIX+"/book-domain-list");
								return;
								setReqWait(false);
							} else {
								setReqWait(false);
								jtoast.error("Something went wrong [UBD-400]");
							}
						} else {
							setTimeout(listen, 3000);
						}
					});
			}, 3000);
		}
	}	
	const saveAuction=async()=>{
		let _state:any={
												
			domain:domain,
			contract:CCD_INFO.CONTRACT_INDEX,
			token:tokenId,
			atype:1,
			price:priceinCCD,
			owner:currentAccount,
			startAt:new Date(startDate).getTime(),
			endAt:new Date(moment(calanderEndDate).format("YYYY-MM-DD")).getTime(),
			royality:royality
			
		};
		const _url = process.env.REACT_APP_SDK_API_SERVER+"/api/auction-prepare";	
		try{
			let _response=await axios.post(_url,_state);
			console.log(_response.data);
		}catch(_e:any){
			jtoast.error("Auction has been saved, but something went wrong!");
		}
	}
  return (
	<>
	<div className='container mt-4'>
	<div className='container py-4 px-4'>
	<div className='p-4'>
		<h2 className='rubik-bold-white-40px'>List for Sale</h2>
		<div className='row mt-4'>
			<div className='col-md-4'>
				<div className='card bg-transparent'>
					<div className='card-body'>
						<div className='text-center' style={{padding:'1px'}}>
							<img src={domainImage} style={{width:'85%'}} />
							<div className='text-white text-center fs-5' >
								{domain}
								<div className='f75rem mt-2 text-white'>concordium</div>
								<div className='f75rem text-white mt-2'>Token:{tokenId}</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
			<div className='col-md-8'>
			<div className='fs-4 text-white'>List on marketplace</div>
				<p className=' mt-2 text-white'>
				This will list your domain on the public marketplace which will allow other users to instantly purchase it.
				</p>
				<div className='mt-4'>
					<div className="form-floating mb-4">
						<input type="email" className="form-control" id="price" placeholder="Enter minimum bid" onChange={(e:any)=>setPriceinCCD(e.target.value)} />
						<label >Enter minimum bid in CCD</label>
					</div>
					<div className="form-floating mb-4">
						<input type="email" className="form-control" id="startDate" placeholder="Enter start date" value={startDate} onChange={(e:any)=>setStartDate(e.target.value)} />
						<label >Start Date YYYY-MM-DD</label>
					</div>
					<div className="form-floating mb-4">
						<input  type="text" className="form-control" id="endDate" placeholder="Enter end date"
								value={moment(calanderEndDate).format("YYYY-MM-DD")} 
								onChange={(e:any)=>setEndDate(e.target.value)}
								onFocus={e=>setShowCalander(true)}
						 />
						<label >End Date YYYY-MM-DD</label>
					</div>
					{showCalander===true?
						<div className='blocker'
						id="blocker"
						onClick={(e:any)=>{
							let id=e?.target?.id;
							console.log(e.target);
							if(id.indexOf("blocker")===0) setShowCalander(false);
						}}
						>
							<div className='d-flex flex-column vh-100 ' id="blocker_" style={{paddingTop:'10rem'}}>
								<div className='d-flex justify-content-center ' id="blocker__">
									<div className='calendar-container' >
										<Calendar 
										onChange={(value, event) =>{
											setCalanderEndDate(value);
											//setEndDate(moment(value).format("YYYY-MM-DD"));
											setShowCalander(false);
										}}
										 value={calanderEndDate} minDate={moment().add(-10,'d').toDate()} />
									</div>
								</div>	
							</div>
						</div>
						:
						<></>
					}
					{/*<div className="form-floating mb-4">
						<select className="form-select" id="royality" placeholder="" onChange={(e:any)=>setRoyality(e.target.value)}>
							<option value="0">0%</option>
							<option value="5">5%</option>
							<option value="5">10%</option>
							<option value="5">20%</option>
							<option value="5">25%</option>
						</select>
						<label >Royality</label>
					</div>
  					*/}
					<div>
						{tokenId!="00000000" &&
						<button className='btn btn-warning rounded-pill px-4 -py-4 text-black fw-bold'
						onClick={e=>void(0)/*putIntoAuction(e)*/}
						disabled={true}
						>
							{reqWait===true?
									<><img src={loadingGif} className='ani-xsm' /></>
								:
									<> Create Auction (Coming soon) </>
							}
						</button>
					}
					</div>
				</div>
			</div>	
		</div>	
	</div>
	</div>
	</div>
	</>
  );
}

export default JBookDomain;
